import React from "react";

function Facebook() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      data-name="圖層 1"
      viewBox="0 0 512 512"
    >
      <path
        fill="#d28e77"
        d="M307.72 141.4c5.79.6 17.23 12 25.87 8.11 4.36-1.63 7.67 2.63 8.22 4-3.65-5.66-3.89-59.32-.22-63.23-7.45 5.31-7.82 7.06-17.59-.28-9.54-.66-18.69 6.56-33.07 2.12-9.18 2.43-32.42 12.58-44.62 18.14-13.24 18.8-18 26.38-24.76 40.94-1.15-.53-2.3 15.13-4 20.27-.07 15 5 19.07 2.79 40.5-2.43-9.4-3.08-6.55 1.61-4.07-3.08 3.51-48 1.67-50 3.15.27-.69-4.51 62.93 1 62.89-.22-.17 48.91 2.78 48.63-7.9-3.41 7.25 2.29 156-2 159.13 1.33 3.55 64.73-6.56 62.43-12.83.94 7.09-.93-142.14.45-132.58 1.13-1.15 51.35-9.37 51.85-8.8.74-24 3.3-39.4 7.58-60.1-3.32 7.49-8.14 3.42-10 4.06-20.11-.46-53.19-2.91-50 6 0-4-3.35-34.74 2.44-48.49 1.82-15.85 4.16-20.62 23.39-31.03z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Facebook;

import React from "react";

function Icon() {
  return (
    <svg
      id="little-heart"
      xmlns="http://www.w3.org/2000/svg"
      width="152"
      height="152"
      fill="none"
      viewBox="0 0 152 152"
    >
      <g>
        <g>
          <path
            fill="#E8B4B9"
            d="M71.815 27.15c9.32-4.507 16.628-12.516 25.009-18.74 8.38-6.226 19.158-10.789 28.785-7.07 8.1 3.134 13.476 11.566 15.736 20.33 4.151 16.091-.665 33.654-9.664 47.377-8.998 13.723-21.793 24.16-34.91 33.504-3.876 2.779-7.831 5.469-12.193 7.225-11.422 4.675-24.761 2.407-35.275-4.218S30.89 88.4 25.44 76.845a100.82 100.82 0 01-8.644-28.835c-.914-6.386-1.21-12.956.1-19.258 2.74-13.145 12.932-24.25 25.347-27.574 4.605-1.234 9.711-1.4 13.878.995 8.644 4.963 9.251 17.641 14.495 26.412"
          ></path>
          <path
            fill="#E8B4B9"
            d="M75.726 110.605l4.672 36.596-1.634-1.961"
          ></path>
          <path
            fill="#E8B4B9"
            d="M77.613 109.788c1.718 12.145 3.436 24.285 4.996 36.457.31 2.407-1.58 6.48-3.838 3.498a25.163 25.163 0 01-1.472-2.193c-1.143-1.805-.054-7.459 2.537-5.142.604.537-.96 2.744-.406 3.356l-1.238 2.388c-1.57-12.225-2.944-24.478-4.343-36.736-.147-1.279.04-3.256 1.37-3.761 1.33-.506 2.237.919 2.409 2.149l-.015-.016z"
          ></path>
          <path
            fill="#E8B4B9"
            d="M56.179 109.955l-.329 29.486c0 1.349.147 3.015 1.29 3.515"
          ></path>
          <path
            fill="#E8B4B9"
            d="M58.032 109.955c.06 5.261.206 10.522.197 15.794 0 2.455 0 4.917-.03 7.373 0 1.402-.024 2.81-.04 4.213 0 .537-.289 2.252.094 2.649 1.531 1.58 1.38 7.524-1.698 6.293-2.282-.903-2.89-3.762-2.994-6.142-.137-3.192.054-6.449.113-9.647.128-6.851.412-13.692.643-20.533.123-3.574 3.676-3.623 3.715 0z"
          ></path>
          <path
            fill="#fff"
            d="M69.649 75.051c-3.734-1.78-5.813-1.39-10.228-.596-1.143-.285-.982-3.257.275-3.181 5.18-.73 6.739-.73 9.953.687 1.325 0 1.33 3.015 0 3.09z"
          ></path>
          <path
            fill="#E8B4B9"
            d="M99.69 80.092l17.716 26.638-17.717-26.638z"
          ></path>
          <g fill="#E8B4B9">
            <path d="M18.824 69.898c-10.611-3.632-12.39-5.09-14.99-9.378-1.621-2.675.725-11.295.725-11.295s5.658.809 4.908 2.353C7.388 63.27 13.426 63.161 23.808 66.306c3.105 3.126-2.869 5-4.985 3.578v.014z"></path>
            <path d="M15.758 41.535c6.234.176 3.429 4.815-2.014 8.157-2.887 1.772-11.349 3.946-10.49-.869.5-2.8 4.923-7.502 12.504-7.288z"></path>
          </g>
          <path
            fill="#fff"
            d="M65.188 65.099c-2.728 0-2.738-6.363 0-6.363 2.739 0 2.739 6.363 0 6.363z"
          ></path>
          <path
            fill="#fff"
            d="M80.849 57.29c-.535.538-.629.624-.28.221a1.31 1.31 0 01.245-.285c.304-.289.688-.459 1.09-.483a2.045 2.045 0 011.497.537c1.114.914 1.506 2.655 1.501 4.09 0 2.267-1.33 6.11-3.965 5.55a3.46 3.46 0 01-1.534-.91c-.434-.44-.76-.992-.95-1.604a7.65 7.65 0 01.06-4.955c.382-1.15 1.04-2.526 2.237-2.778 1.939-.409 2.734 1.929 2.827 3.643.064 1.145-.49 3.01-1.654 3.402-1.163.392-2.164-.468-2.547-1.612l-.03-.086 1.355 1.612c.923.312 1.577-.074 1.963-1.155l-.093.225c-.25.575.044-.43.044-.634 0-.591-.23-1.86-.937-2.15a.838.838 0 00-.524-.04.896.896 0 00-.457.282c-.241.334-.3.403-.182.22.118-.182.073-.096-.147.307a3.39 3.39 0 00.103-.29c.078-.237.049-.15-.079.258-.08.341-.062.701.05 1.032.012.341.107.673.274.961l-.147-.21c-.113-.15-.201-.306-.31-.461l.825.752-.127-.08h.957l-.143.053c-2.503.935-3.008-4.417-.952-5.411h.03z"
          ></path>
          <path
            fill="#fff"
            d="M49.76 51.986c.53.054 1.035.272 1.455.63.42.358.738.841.915 1.391a5.95 5.95 0 01.283 2.575 5.791 5.791 0 01-.832 2.428 5.353 5.353 0 01-2.062 1.956 2.427 2.427 0 01-1.755.095 2.667 2.667 0 01-1.42-1.132c-1.38-2.053-.982-4.95.216-6.986.55-.908 1.398-1.811 2.454-1.849 1.472-.048 2.454 1.537 2.675 2.983.191 1.268 0 3.122-1.031 3.966a1.778 1.778 0 01-.695.377c-.254.07-.519.08-.776.03a1.752 1.752 0 01-.717-.325 1.948 1.948 0 01-.531-.62c.383.393.466.506.24.339l.55.365-.103-.048.609.13a.511.511 0 00-.221 0l.608-.13a.599.599 0 00-.245.108l.545-.366c-.378.312.402-.537-.035.043-.3.398.275-.741.094-.215-.104.296-.153.704.063-.312-.094.642-.103.173-.024-1.407a3.072 3.072 0 00-.795-1.661l-.55-.365a.63.63 0 00.245.123l-.613-.129c.099.022.2.022.3 0l-.614.13c.154-.04.302-.102.442-.184l-.55.366c.255-.173.498-.367.726-.58.138-.13.393-.463-.073.112l.083-.14c-.805 1.401-.6 2.835.614 4.3-1.154-.468-1.473-2.15-1.394-3.343.088-1.075.697-2.724 1.914-2.644l.005-.01z"
          ></path>
          <path
            fill="#fff"
            d="M83.928 55.14a4.227 4.227 0 01-.277 2.507 3.744 3.744 0 01-1.623 1.793c-.535.29-.706-.693-.662-1.043.098-.815.098-1.64 0-2.456h1.01a4.75 4.75 0 01-.519 1.68c-.273.515-.64.966-1.075 1.324a.404.404 0 01-.526.016.47.47 0 01-.127-.15 4.346 4.346 0 01-.705-1.895 4.486 4.486 0 01.214-2.033.523.523 0 01.104-.216.462.462 0 01.184-.137.423.423 0 01.429.063c.128.145.214.329.247.528s.012.404-.06.59a2.303 2.303 0 00-.036 1.004c.062.333.197.645.394.908l-.653-.134c.302-.228.558-.522.753-.863.194-.34.322-.722.376-1.12.108-.758.898-.747 1.01 0a12.5 12.5 0 01.045 3.343l-.702-1.021c.416-.217.76-.57.981-1.012.222-.442.312-.949.256-1.45-.044-.784.775-1.026.952-.204l.01-.021z"
          ></path>
          <ellipse
            cx="78.913"
            cy="147.323"
            fill="#E8B4B9"
            rx="4.679"
            ry="4.677"
          ></ellipse>
          <ellipse
            cx="55.52"
            cy="143.426"
            fill="#E8B4B9"
            rx="4.159"
            ry="3.897"
          ></ellipse>
          <path
            fill="#fff"
            d="M51.913 51.068c-.14.927-.581 1.769-1.242 2.37a.388.388 0 01-.27.111c-.1 0-.195-.04-.27-.112a3.082 3.082 0 01-.777-1.818 3.17 3.17 0 01.463-1.943c.49-.79 1.1.43.834 1.005-.319.677-1.36 3.224-2.321 2.515-.451-.323-.554-1.118-.608-1.661a5.62 5.62 0 01.23-2.214c.285-.935 1.139-.07.982.645a4.676 4.676 0 00-.148.973c0 .166.045.333.04.494l.044.236c-.162-.091-.182-.188-.069-.284.054-.237.383-.57.49-.785.183-.3.354-.613.492-.924l.785 1.166a.902.902 0 00.103 1.144h-.54c.413-.326.725-.784.893-1.31.24-.909 1.031-.41.889.38v.012z"
          ></path>
        </g>
        <path
          fill="#E8B4B9"
          d="M122.869 79.964c10.425.324 13.109-.877 18.757-4.595 2.635-1.734 3.792-7.567 3.792-7.567s-4.948-1.073-5.186.387c-4.661 9.96-9.417 8.04-19.396 7.396-4.165 1.55-.414 4.86 2.042 4.368l-.009.011z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M141.577 60.295c-5.066-1.753-5.337 2.8-2.803 7.119 1.344 2.29 6.917 6.594 8.835 2.492 1.115-2.385.129-7.48-6.032-9.611z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

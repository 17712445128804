import React from "react";

function Icon() {
  return (
    <svg
      id="wave"
      xmlns="http://www.w3.org/2000/svg"
      width="1681"
      height="334"
      fill="none"
      viewBox="0 0 1681 334"
    >
      <path
        stroke="#D28E77"
        strokeWidth="2"
        d="M.471 126.308c41.875-19.59 155.628-51.31 250.919-14.959 74.631 28.471 65.808 80.69 166.219 107.671 111.762 30.032 183.247-91.214 196.615-117.171 13.291-25.807 43.809-118.014 223.459-96.93 201.217 23.615 87.314 267.247 23.192 308.876-64.122 41.629-199.359 11.106-159.505-57.457 39.854-68.563 166.141-43.91 225.83-14.244 59.689 29.666 200.42 85.111 302.43 84.115 183.55-1.791 194.51-165.838 450.58-150.484"
        opacity="0.31"
      ></path>
    </svg>
  );
}

export default Icon;

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="54"
      data-name="圖層 1"
      version="1.1"
      viewBox="0 0 25 17.669"
    >
      <path
        fill="#d28e77"
        fillRule="evenodd"
        strokeWidth="0.086"
        d="M2.492 17.46c-.91-.416-1.953-1.264-1.975-2.427C.227 12.714-.58 3.64.706 1.347 1.55 1.152 2.47.317 3.034.71 7.74-.009 20.532-.24 22.757.29c.984.424.756.978 1.17 1.79 1.213 2.932 1.557 11.04.192 13.437.163.556-.544 1.16-1.487 1.618-1.385.447-17.785.761-20.14.325zm6.847-4.668c2.604-1.394 5.05-2.835 7.752-4.288-2.44-1.374-5.185-2.491-7.873-4.031.092 2.91.195 5.49.12 8.32z"
        className="cls-1"
      />
    </svg>
  );
}

export default Icon;

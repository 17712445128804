import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="335"
      height="172"
      fill="none"
      viewBox="0 0 335 172"
      id="plant-left"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path
          fill="#C1DEE2"
          d="M245.528 84.905c13.125-3.19 27.332 2.83 15.455 6.021-6.566 1.764-13.43 1.136-17.295-.333-2.508 1.012-4.856 2.07-7.112 2.965a396.332 396.332 0 00-19.58 8.368c-12.898 5.93-23.952 12.194-35.509 18.345-11.406 6.07-22.72 12.171-33.058 18.395-8.758 5.273-20.431 11.476-23.429 17.257 4.549-12.26 21.812-24.045 42.368-34.853 20.39-10.719 43.789-22.426 75.359-30.886-3.213-1.564-3.302-3.797 2.801-5.279z"
          opacity="0.5"
        ></path>
        <path
          fill="#1FA7C1"
          d="M2.296 52c7.345.476 19.347 5.977 25.401 8.072A232.571 232.571 0 0147.574 68c13.247 5.986 25.094 12.351 35.168 18.989 16.423 10.82 29.58 21.928 39.129 33.711 5.845 7.213 9.629 14.689 11.872 22.113.424 1.4 4.858 12.194-1.531 12.485-5.746.262-3.972-8.196-4.249-9.347-3.134-12.98-12.521-25.609-25.579-37.844-2.844-2.665-5.486-5.929-10.035-8.327-3.275-1.728-.023-1.126-5.78-1.78-4.942-.561-10.574-.424-15.635-1.111-10.996-1.493-19.8-4.391-27.518-7.306-14.998-5.665-26.883-11.794-33.078-19.053C6.9 66.505 3.743 62.456 1.99 58.307c-.453-1.072-4.424-6.613.306-6.306z"
        ></path>
        <path
          fill="#89C5CC"
          d="M329.899 17.77c.427-.203 3.205-1.62 1.806-1.53 2.849-.185-3.918 1.563 3.18.49-3.37 1.055-1.425 2.423-1.38 3.841.073 2.303-1.091 4.586-2.4 6.84-2.07 3.56-5.154 7.023-9.629 10.336-9.755 7.219-26.319 14.168-45.984 18.681-9.708 2.228-19.222 2.987-30.294 4.013-6.994.648-7.548 1.845-11.261 4.092-7.728 4.68-15.319 9.342-22.135 14.18-8.138 5.775-16.039 11.558-23.491 17.435-25.107 19.799-45.541 40.311-56.272 61.539-.263-12.509 8.479-25.393 19.505-37.317 8.032-8.687 18.489-17.061 29.642-25.35 2.702-2.01 6.798-4.592 7.945-6.81.804-1.553-1.653-3.047-2.778-4.684-4.385-6.372-4.071-13.512-.22-19.918 4.246-7.065 16.525-10.344 37.298-11.345 7.597-.366 17.252-.232 24.218-1.304 7.59-1.168 10.72-5.006 15.036-7.47 11.591-6.618 25.364-12.966 41.178-18.543 4.379-1.544 9.088-2.969 14.084-4.29 3.15-.832 9.805-1.859 11.952-2.885z"
        ></path>
        <path
          fill="#C4FBEA"
          d="M206.288 9.316c5.044-2.381 14.404-8.428 21.503-9.279 4.573-.548 3.24 5.12 3.296 6.202.22 4.182-1.009 8.337-2.528 12.483-2.736 7.479-11.682 14.139-23.953 20.504-6.314 3.275-13.72 6.59-23.978 8.633-4.722.941-10.403 1.099-15.066 1.91-5.431.944-2.473.18-4.92 2.054-3.399 2.601-4.486 5.957-6.059 8.732-7.22 12.74-24.701 80.09-21.67 93.049.269 1.149-4.569 1.423-4.811.021-1.283-7.435 9.825-58.924 15.429-75.763 5.605-16.838 11.276-31.714 16.963-37.54 6.899-7.068 19.358-15.567 29.73-22.158a145.26 145.26 0 0116.064-8.848z"
        ></path>
        <path
          fill="#7E7E7E"
          d="M92.002 150h73.72c3.314 0 6.001.89 6.001 1.987a.704.704 0 01-.064.29l-5.698 12.79c-1.737 3.899-11.844 6.788-23.749 6.788h-26.7c-11.905 0-22.012-2.889-23.75-6.788l-5.697-12.79c-.484-1.086 1.783-2.096 5.062-2.256.29-.014.582-.021.875-.021z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

import React from "react";

function Member() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="26.971"
      data-name="圖層 1"
      version="1.1"
      viewBox="0 0 40 26.971"
    >
      <path
        fill="#d28e77"
        strokeWidth="0.062"
        d="M1.968 25.963c-1.096-4.622.317-9.7 3.907-12.872a11.665 11.665 0 015.835-2.768c1.11-.18 2.181-.36 2.935-1.28.707-.863.985-2.005 1.534-2.967 1.024-1.796 2.614-3.407 4.6-4.096a6.25 6.25 0 015.542.708 5.602 5.602 0 011.897 2.18c.254.508.436 1.054.754 1.53a4.218 4.218 0 001.28 1.196c.896.577 1.772 1.12 2.569 1.835a15.657 15.657 0 012.048 2.228c2.824 3.755 4.112 8.705 3.292 13.35-.18 1.015 1.379 1.452 1.557.428.803-4.542-.13-9.304-2.536-13.239a17.467 17.467 0 00-4.499-4.944c-.96-.71-2.093-1.12-2.636-2.246-.508-1.06-.985-2.041-1.846-2.867-1.576-1.5-3.829-2.31-6-2.109-2.305.215-4.397 1.449-5.923 3.16a12.077 12.077 0 00-1.913 2.878c-.252.535-.441 1.1-.738 1.613-.319.554-.751.788-1.365.923-1.158.247-2.285.386-3.399.821a13.328 13.328 0 00-3.114 1.707C1.049 14.624-.93 20.743.41 26.395c.239 1.01 1.797.582 1.557-.43z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.062"
        d="M18.425 8.698a.573.573 0 00-.296-.083.56.56 0 00-.477.276.59.59 0 00-.081.295.708.708 0 00.02.173.612.612 0 00.064.159.57.57 0 00.207.21.534.534 0 00.136.059.567.567 0 00.296 0l.13-.056c.038-.02.198-.165.115-.087a1.056 1.056 0 00.098-.121.615.615 0 00.054-.415.602.602 0 00-.248-.322l-.128-.054a.545.545 0 00-.286 0l-.13.054a.54.54 0 00-.196.197l.084-.108a.093.093 0 01-.01.01l.109-.085h-.006l.128-.054.143-.02.143.02h-.005l.128.054h-.005l.109.084-.006-.006.085.108a.045.045 0 01-.01-.014l.055.129a.107.107 0 01-.008-.03l.019.142a.129.129 0 010-.028l-.02.143a.056.056 0 010-.014l-.054.128a.025.025 0 010-.007l-.085.11-.108.083h.005l-.129.054-.143.02h.007l-.144-.02h.01l-.129-.054.011.007a.4.4 0 00.197.064.408.408 0 00.215-.01.607.607 0 00.322-.247l.054-.129a.54.54 0 000-.286l-.054-.129a.54.54 0 00-.197-.196z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.062"
        d="M25.846 8.376c.69 0 .695-1.077 0-1.077-.694 0-.689 1.077 0 1.077z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.062"
        d="M21.526 9.412l2.511.806-.177-.672a2.265 2.265 0 01-1.963.62c-.508-.086-.727.69-.215.78a3.118 3.118 0 002.75-.831.408.408 0 00-.179-.675l-2.512-.806c-.492-.159-.708.62-.215.778z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.062"
        d="M18.476 14.08a25.136 25.136 0 005.527-.233c.512-.08.295-.858-.215-.78a24.215 24.215 0 01-5.317.206c-.518-.035-.516.772 0 .808z"
        className="cls-1"
      />
    </svg>
  );
}

export default Member;

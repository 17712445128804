import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="129"
      height="72"
      fill="none"
      viewBox="0 0 129 72"
      id="littleHeart"
      className="little-heart-svg"
    >
      <g>
        <path
          fill="#E8B4B9"
          d="M62.587 14.39c8.332-1.989 14.865-5.522 22.356-8.268 7.491-2.746 17.126-4.76 25.731-3.119 7.241 1.383 12.047 5.102 14.067 8.97 3.71 7.097-.595 14.846-8.638 20.9-8.044 6.053-19.482 10.658-31.207 14.78-3.465 1.226-7 2.412-10.9 3.187-10.21 2.062-22.134 1.062-31.532-1.86-9.399-2.924-16.46-7.57-21.332-12.668-3.839-4.004-6.446-8.296-7.727-12.72-.817-2.817-1.081-5.716.09-8.496 2.45-5.799 11.56-10.698 22.657-12.164 4.117-.544 8.681-.618 12.406.439 7.727 2.19 8.27 7.782 12.957 11.651"
        ></path>
        <path
          fill="#E8B4B9"
          d="M62.106 13.085c11.801-2.896 19.68-8.827 32.099-11.247 5.632-1.1 12.004-1.471 17.692-.245 5.249 1.116 9.248 3.371 11.91 5.921 5.915 5.662 5.499 12.542 1.704 18.522-4.097 6.454-12.438 11.86-22.148 16.306a193.982 193.982 0 01-15.3 6.098c-5.314 1.902-10.79 3.871-17.149 4.68-12.377 1.574-25.363-.54-35.059-4.658-9.544-4.06-16.205-9.838-20.368-15.808-4.461-6.395-7.553-14.221-2.53-20.77C17.474 5.972 29.007.85 41.694.85c6.4 0 11.395 1.805 14.425 4.644 2.833 2.66 3.956 5.737 7.015 8.337.826.703.977 1.716 0 2.412-.835.589-2.445.684-3.252 0-2.455-2.089-3.833-4.394-5.58-6.65-1.638-2.113-3.776-4.168-8.166-4.977-5.136-.934-11.117 0-15.753 1.246-4.377 1.2-8.11 2.949-10.857 5.088-6.679 5.183-4.909 11.769-1.798 17.445 3.082 5.625 8.497 11.178 16.522 15.34 7.902 4.099 18.523 6.791 29.87 6.49 11.721-.312 21.767-4.59 30.684-8.188 16.889-6.826 34.648-17.933 27.633-29.913-1.567-2.675-4.65-5.27-9.271-6.787-5.424-1.78-12.056-1.557-17.862-.441-12.136 2.329-20.6 7.946-32.25 10.803-2.337.574-3.083-2.09-.944-2.607l-.005-.007z"
        ></path>
        <path fill="#E8B4B9" d="M67.437 52.005l4.494 16.697-1.572-.895"></path>
        <path
          fill="#E8B4B9"
          d="M69.251 51.632c1.653 5.541 3.305 11.08 4.806 16.634.297 1.098-1.52 2.957-3.692 1.596a16.29 16.29 0 01-1.416-1c-1.1-.824-.052-3.404 2.44-2.347.581.245 1.134.49 1.667.77l-3.248 1.851c-1.51-5.578-2.832-11.168-4.177-16.761-.142-.584.038-1.486 1.317-1.717 1.28-.23 2.152.42 2.318.981l-.015-.007z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M48.635 51.708l-.317 13.454c0 .615.142 1.375 1.242 1.603"
        ></path>
        <path
          fill="#E8B4B9"
          d="M50.417 51.708c.056 2.4.198 4.8.189 7.206 0 1.12 0 2.244-.029 3.364 0 .64-.023 1.283-.038 1.923 0 .245-.278 1.027.09 1.208 1.473.721 1.327 3.433-1.633 2.872-2.195-.412-2.78-1.717-2.88-2.803-.132-1.456.052-2.942.109-4.401.123-3.127.397-6.248.618-9.37.118-1.63 3.536-1.652 3.574 0z"
        ></path>
        <path
          fill="#fff"
          d="M61.591 35.783c-3.205.085-6.694.098-9.837-.273-1.1-.13-.944-1.485.264-1.451 3.21.086 6.344.314 9.573.314 1.275 0 1.28 1.375 0 1.41z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M23.295 34.515c-6.435 2.2-11.884 5.092-15.979 8.481l15.979-8.481z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M24.24 35.717c-6.402 2.287-10.933 5.148-15.423 8.395-.816.593-2.176.566-3.007 0-.944-.648-.712-1.574 0-2.231 3.777-3.492 10.135-6.412 16.522-8.567 2.53-.851 3.824 1.716 1.888 2.403h.02z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M90.485 38.083l17.04 12.154-17.04-12.154z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M91.831 37.082c2.941 2.077 5.938 4.132 8.889 6.209 2.832 2.008 6.287 3.994 8.496 6.213.751.757-.66 3-2.681 1.986-3.394-1.717-5.933-4.075-8.69-6.069-2.917-2.106-5.782-4.232-8.704-6.338-1.417-1.025.703-3.403 2.69-2z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M110.241 50.17c.864-.279 1.505.721.547.895-.958.175-1.911-.424-1.789-.948.123-.525.907-.934 1.657-1.292.519-.246 1.209-.513 1.888-.383.864.17.826.807.59 1.273-.325.635-.812 1.316-1.888 1.652-1.076.336-2.884.05-2.936-.61.014-.242.167-.476.439-.672.869-.71 2.215-1.224 3.776-1.444.13.279.152.569.064.852-.087.284-.282.556-.573.8a5.194 5.194 0 01-1.742-.152"
        ></path>
        <path
          fill="#E8B4B9"
          d="M109.329 49.01c3.696-1.164 5.447 2.732 1.983 3.47-2.162.464-3.942-.75-4.249-1.716-.406-1.209.595-2.239 2.195-3.072 1.369-.709 3.39-1.187 4.933-.324 1.327.736 1.416 1.917.944 2.847-.472.93-1.204 1.961-2.832 2.528-.675.244-1.484.368-2.307.355-.822-.014-1.614-.165-2.258-.431-2.077-.903-1.888-2.516-.623-3.627 1.085-.932 2.795-1.622 4.801-1.94 1.416-.22 2.398.454 2.653 1.117.375.912.182 1.863-.553 2.714-.42.466-.967.9-2.034.954a8.068 8.068 0 01-2.299-.246c-1.331-.333-1.737-1.226-1.473-1.922.222-.588 1.223-1.385 2.592-1.093.371.084.782.107 1.18.068l-1.515.466s.094.285-.052-.103l2.648 1.118a8.59 8.59 0 00-1.638.375c-.437.2-.838.42-1.199.657.359-.521.267-.946-.274-1.275a.361.361 0 00-.151-.068c.139.021.281.036.425.044a1.35 1.35 0 00.323-.094.73.73 0 00.229-.151c.141-.167.26-.338.354-.513.161-.245-.028 0 .047.147.133.245.945.503.779.706a6.104 6.104 0 01-1.019.535c.821-.093 0-.794-.335-.917-.751-.164-1.185.154-1.294.956-.06.528.412.817 1.416.866-2.327.353-3.082-1.91-1.388-2.452l-.009.022z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M4.606 43.693c-.184-.523 1.841-.469 1.804.058-.038.527-1.454.773-2.36.52-.907-.252-1.35-.777-1.719-1.27-.255-.34-.472-.77 0-1.042.618-.358 1.747-.108 2.493.174 1.024.39 2.072.87 2.28 1.523.207.652-.945 1.414-2.162 1.199-.43-.094-.793-.253-1.044-.456-.958-.68-1.392-1.52-1.218-2.357a7.04 7.04 0 011.552.279c.476.145.893.338 1.224.567-.13.306-.446.582-.902.787"
        ></path>
        <path
          fill="#E8B4B9"
          d="M2.875 44.048c-.27-.902.387-2.023 2.407-2.128 1.676-.086 2.776.652 3.05 1.442.372 1.083-.789 2.329-3.012 2.5-1.7.13-3.102-.468-3.923-1.194C.33 43.727-.458 42.526.302 41.45c.732-1.03 2.61-1.488 4.55-1 3.673.929 6.033 3.491 3.13 5.316-1.695 1.066-4.12.794-5.598-.246C.99 44.511.328 43.28.514 42.042c.08-.524.619-1.733 2.153-1.598a10.67 10.67 0 012.337.407c.72.214 1.35.501 1.855.843 1.52 1.087.439 2.506-1.256 3.332-1.062.518-2.431 0-2.884-.561-.286-.356-.366-.748-.23-1.128.134-.38.478-.73.99-1.01.09-.1.056-.054-.104.137l.552 1.55c-.333-.19-.774-.321-1.26-.373l2.152-1.599a.932.932 0 00.057.714c.213.17.399.349.557.535 0 .08-.165-.037-.297.06a.47.47 0 00-.109.278c0 .03-.17.581.038.49.07-.03-.505-.299-.472-.294-.36-.165-.747-.314-1.157-.444a.538.538 0 00-.148-.048.753.753 0 00-.173-.01c-.77.053-.425-.295 1.025-1.045-.19-.088.174.194.221.245.083.138.248.26.472.346-.037-.015-.15-.189.222-.025h-.042c-.327.242-.533.521-.6.814l.27.736c.096.138.281.254.523.328.133.04.104.037-.09 0 .473.032.832-.12 1.049-.438l.245-.68v.091c-.038.505-.434 1.226-1.562 1.312-1.129.086-1.827-.454-1.978-.956l.005-.003z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M70.456 67.31c1.09-.572 3.366.137 2.922.91-.443.772-3.058.735-3.44-.043-.227-.796 1.713-1.47 3.115-1.127 1.402.343 1.751 1.436.76 2.054-.992.618-2.941.687-4.206.221-.472-.166-.835-.403-1.04-.679-.205-.276-.243-.578-.107-.866.34-.561 1.751-.914 2.714-.6"
        ></path>
        <path
          fill="#E8B4B9"
          d="M69.196 66.375c1.265-.735 3.111-.929 4.678-.267 1.837.775 2.177 2.346.864 3.354-.446.354-1.104.621-1.871.761a6.994 6.994 0 01-2.377.014c-2.247-.392-2.979-1.76-2.526-2.861.42-1.03 2.13-1.98 4.249-1.994 2.195-.014 3.687 1.02 4.14 2.04.495 1.11 0 2.452-1.714 3.215-.698.306-1.548.504-2.452.573a9.11 9.11 0 01-2.665-.186c-2.606-.625-3.776-2.268-3.144-3.678.524-1.16 2.984-2.383 5.382-1.699 1.317.378 1.77 1.204 1.491 1.947-.212.569-1.255 1.432-2.624 1.106-.368-.089 0-.057-.406-.042.344 0-.203-.044-.076.059l.095-.047c.249-.208.353-.455.297-.698.038-.02-.066-.123-.043-.14-.056.039.628.164.657.166.432.053.887.017 1.27-.1-.166.044.165-.194.042-.034a.808.808 0 00-.189.291c-.003.12.03.24.1.356.16.12.15.113-.03-.025l.081.062c.27-.03-.325.012-.085-.017-.137.024-.28.039-.425.044l.062-.032c-.222.103-.265.11-.123.022-.047-.115.165-.812.217-.392l-.288-.793c.246.383-.698-.404-.207-.144-.232-.123.136-.032-.123-.03.037 0 .675.037.255.03.099-.01.196-.027.288-.05.146-.034.122-.026-.085.028l-.16.093c-.203.166-.33.354-.372.55a.734.734 0 00.126.578l.09.108c.15.088.122.086-.09-.013.198-.029-.472.023-.132-.083-2.262.721-3.46-1.31-2.158-2.077l-.019.005z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M47.854 66.603c.944-.078 1.728.582 1.28 1.028-.131.105-.301.195-.5.265s-.422.12-.658.144a3.446 3.446 0 01-.715 0 2.891 2.891 0 01-.658-.145c-.386-.154-.68-.361-.846-.599-.168-.237-.201-.495-.098-.742.312-.848 2.12-1.486 3.715-1.253 1.596.233 2.478 1.356 1.539 2.067-.94.711-3.422.628-4.074-.164-.651-.792 1.166-1.797 2.69-1.459"
        ></path>
        <path
          fill="#E8B4B9"
          d="M47.855 65.289c1.978-.098 3.153 1.04 3.262 1.918.141 1.103-1.152 2.324-3.512 2.341-2.214.017-3.611-1.123-3.932-2.138-.196-.557-.114-1.131.237-1.668.35-.536.96-1.017 1.769-1.397.632-.293 1.41-.487 2.244-.56a8.44 8.44 0 012.476.143c1.685.392 2.676 1.332 2.955 2.244.316 1.037-.26 2.275-2.016 2.89-.671.238-1.461.369-2.273.377a7.327 7.327 0 01-2.3-.33c-4.05-1.321-1.823-5.816 3.355-4.882 2.545.459 1.813 3.541-1.128 3.038-.037 0-.099 0-.316.037.151-.042.113-.02-.113.069a.422.422 0 00.118-.057.194.194 0 00.066-.076c.193-.272.184-.572-.024-.84a.635.635 0 00-.221-.202l.07.017c.26.037.293.05.104.032.132.015.845-.027.585 0 .175-.022.27.015.288-.07-.033.144-.354.276-.349.472-.01.1.025.2.104.29-.123-.147-.128-.16 0-.032a.661.661 0 00-.3-.138 1.178 1.178 0 00-.399-.017c-.306.013-1.156.23-1.057.344-.024-.027.156-.268.132-.341a.232.232 0 00-.074-.137.536.536 0 00-.176-.108c-.1.034-.028.056.217.063.146-.154.26.037.203.015-.736-.294-.557 1.332.033 1.337-2.403-.023-2.36-2.511 0-2.63l-.028-.004z"
        ></path>
        <path
          fill="#fff"
          d="M57.3 31.242c-2.624 0-2.633-2.903 0-2.903 2.635 0 2.635 2.903 0 2.903z"
        ></path>
        <path
          fill="#fff"
          d="M72.364 27.679c-.515.245-.605.285-.27.1.067-.049.146-.092.237-.13.292-.131.66-.209 1.047-.22.523-.015 1.037.072 1.44.245 1.072.417 1.45 1.211 1.445 1.866 0 1.035-1.28 2.788-3.814 2.533a4.933 4.933 0 01-1.476-.416c-.417-.2-.73-.452-.913-.732-.51-.732-.49-1.535.057-2.26.368-.525 1-1.153 2.152-1.268 1.865-.186 2.63.88 2.72 1.663.06.522-.473 1.373-1.592 1.552-1.118.179-2.081-.214-2.45-.736l-.028-.04 1.303.736c.888.143 1.517-.033 1.888-.527l-.09.103c-.24.262.043-.196.043-.29 0-.269-.222-.848-.902-.98a1.658 1.658 0 00-.504-.018c-.167.02-.32.064-.44.128-.231.152-.288.184-.174.1.113-.082.07-.043-.142.14.038-.044.07-.088.1-.132.075-.108.046-.068-.076.118a.44.44 0 00.047.47c.013.156.104.308.264.44l-.141-.096c-.109-.069-.194-.14-.298-.21l.793.342-.123-.036h.921l-.137.024c-2.407.427-2.894-2.015-.916-2.469h.029z"
        ></path>
        <path
          fill="#fff"
          d="M42.46 25.259c.51.024.996.124 1.4.287.404.164.71.384.88.635.271.374.364.777.273 1.175-.093.397-.367.777-.802 1.108-.495.384-1.18.692-1.982.892a4.774 4.774 0 01-1.688.043c-.556-.086-1.038-.268-1.367-.516-1.326-.937-.944-2.258.208-3.188a3.96 3.96 0 012.36-.843c1.417-.022 2.36.701 2.573 1.36.184.58 0 1.425-.991 1.81-.197.082-.425.14-.669.173a3.369 3.369 0 01-.746.013 2.847 2.847 0 01-.69-.148 1.611 1.611 0 01-.51-.283c.368.179.448.23.231.154l.529.167-.1-.022.586.059a1.033 1.033 0 00-.212 0l.585-.059a.989.989 0 00-.236.05l.524-.168c-.364.143.387-.245-.033.02-.288.182.264-.338.09-.098-.1.135-.147.321.06-.142-.09.292-.098.078-.023-.643-.09-.288-.359-.554-.764-.757l-.53-.167c.071.027.151.046.237.056l-.59-.058c.095.01.193.01.288 0l-.59.058a2.97 2.97 0 00.424-.083l-.528.167c.245-.08.479-.168.698-.265.133-.059.378-.21-.07.051l.08-.063c-.774.639-.578 1.293.59 1.961-1.11-.213-1.416-.98-1.34-1.525.084-.49.67-1.243 1.84-1.206l.005-.005z"
        ></path>
        <path
          fill="#fff"
          d="M75.323 26.698c.159.386.065.788-.266 1.144-.33.355-.88.643-1.56.818-.515.132-.68-.317-.638-.476a2.252 2.252 0 000-1.12h.973c-.066.27-.236.53-.5.766a3.414 3.414 0 01-1.034.604.649.649 0 01-.162.04.814.814 0 01-.18.003.681.681 0 01-.164-.036.384.384 0 01-.122-.068c-.363-.254-.596-.552-.678-.865-.083-.313-.012-.632.206-.927a.24.24 0 01.1-.099.569.569 0 01.177-.063.838.838 0 01.412.03c.123.066.206.15.238.24s.011.184-.059.27a.522.522 0 00-.034.457c.06.152.19.295.379.415l-.628-.061c.29-.104.537-.238.724-.394.187-.156.31-.33.362-.511.103-.346.864-.34.972 0 .158.505.172 1.018.043 1.525l-.675-.466c.4-.098.73-.26.943-.462.214-.201.3-.433.246-.66-.042-.359.746-.47.916-.094l.01-.01z"
        ></path>
        <path
          fill="#fff"
          d="M44.532 24.84c-.135.423-.56.807-1.195 1.08a.636.636 0 01-.26.052.636.636 0 01-.259-.051c-.428-.223-.693-.517-.748-.83-.055-.314.103-.627.446-.887.472-.36 1.057.197.802.459-.306.309-1.307 1.471-2.232 1.148-.435-.148-.534-.51-.586-.758-.068-.341.007-.686.222-1.01.274-.427 1.095-.032.944.294a1.14 1.14 0 00-.141.444c0 .076.042.152.037.225l.043.108c-.156-.041-.175-.086-.066-.13.052-.108.368-.26.472-.358.174-.137.34-.28.472-.422l.755.533c-.114.077-.167.174-.148.27.018.098.107.187.247.252h-.519c.397-.15.697-.358.86-.598.23-.415.99-.187.854.174v.005z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

import React from "react";

function FilledHeart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="36.937"
      data-name="圖層 1"
      version="1.1"
      viewBox="0 0 40 36.937"
      className="filledHeart"
    >
      <path
        fill="#d28e77"
        strokeWidth="0.099"
        d="M8.259 3.579c-3.7.757-6.541 4.091-7.378 7.778-.836 3.688.093 7.597 1.867 10.93 2.888 5.431 7.841 9.464 13.046 12.739 1.048.657 2.168 1.309 3.403 1.37 1.018.05 2.01-.31 2.948-.708a32.884 32.884 0 0014.007-11.33c1.179-1.679 2.209-3.496 2.754-5.475.475-1.725.57-3.535.507-5.324-.11-3.104-.733-6.285-2.475-8.858C35.196 2.127 32.169.276 29.076.574c-1.882.18-3.606 1.106-5.206 2.115a45.596 45.596 0 00-4.07 2.883c-1.678 1.329-1.762 1.316-3.456.09-2.3-1.67-5.233-2.666-8.085-2.083z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.099"
        d="M8.11 3.037C4.584 3.78 1.87 6.669.692 9.986c-1.415 3.985-.506 8.46 1.344 12.146 1.777 3.546 4.492 6.564 7.518 9.095a52 52 0 004.846 3.567c1.785 1.17 3.654 2.457 5.893 2.073 1.037-.175 2.02-.635 2.97-1.07q1.349-.616 2.636-1.355a33.842 33.842 0 004.878-3.41 32.896 32.896 0 004.147-4.148c1.322-1.585 2.55-3.29 3.472-5.141 1.006-2.025 1.508-4.18 1.587-6.436.076-2.138-.099-4.33-.62-6.408-.954-3.817-3.42-7.384-7.33-8.57-1.99-.605-4.004-.335-5.89.484-1.959.85-3.77 2.125-5.477 3.394-.461.343-.915.691-1.366 1.053-.316.248-.648.545-1.022.704a.72.72 0 01-.198.05.456.456 0 01-.26-.057c-.388-.163-.74-.456-1.079-.7-1.667-1.202-3.562-2.063-5.61-2.327a9.339 9.339 0 00-3.02.108c-.71.139-.41 1.224.296 1.086 1.906-.375 3.895 0 5.635.826.904.428 1.701.987 2.52 1.558.543.377 1.152.747 1.84.592.802-.18 1.482-.888 2.105-1.371q1.291-1.008 2.651-1.911c1.638-1.092 3.375-2.207 5.333-2.594 3.681-.729 7.082 1.867 8.69 4.99.882 1.715 1.344 3.64 1.547 5.551.225 2.13.253 4.352-.231 6.449-.456 1.98-1.416 3.782-2.549 5.454a31.384 31.384 0 01-3.62 4.404 32.511 32.511 0 01-9.01 6.464c-.89.43-1.807.865-2.766 1.124a4.193 4.193 0 01-2.955-.27c-.907-.422-1.747-.993-2.582-1.54a57.784 57.784 0 01-2.277-1.567c-3.03-2.198-5.87-4.768-8.016-7.854-2.254-3.24-3.828-7.134-3.555-11.139.227-3.378 2.028-6.695 5.045-8.358a7.954 7.954 0 012.2-.81c.704-.15.405-1.235-.302-1.085z"
        className="cls-1"
      />
      <ellipse
        cx="13.858"
        cy="17.191"
        fill="#fffaf0"
        strokeWidth="0.099"
        className="cls-2"
        rx="1.618"
        ry="1.336"
      />
      <ellipse
        cx="27.077"
        cy="16.63"
        fill="#fffaf0"
        strokeWidth="0.099"
        className="cls-2"
        rx="1.618"
        ry="1.336"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.099"
        d="M19.102 17.664a6.075 6.075 0 01.713.43c.056.039.11.077.162.117.015.012.105.067.021.015a.948.948 0 01.078.063 1.512 1.512 0 01.133.118c.015.014.052.042.059.064-.037-.049-.043-.054-.017-.016.026.037.018.032 0 0-.018-.033-.014-.035 0 0 .014.034.008.034 0 0-.008-.035-.006-.039-.006 0q.017-.099 0-.05c-.009.037.05-.09 0-.012-.018.026-.009.014.028-.035a.641.641 0 01-.037.036c-.052.057.075-.044 0 0l-.068.042c-.05.03-.104.054-.156.078-.03.013-.063.032-.099.042.099-.03-.01 0-.024.01a4.931 4.931 0 01-.622.196.427.427 0 00-.297.518.432.432 0 00.52.297 5.016 5.016 0 00.999-.362c.368-.179.646-.508.566-.94-.07-.37-.43-.61-.71-.817a6.256 6.256 0 00-.835-.518.423.423 0 10-.426.729z"
        className="cls-2"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.099"
        d="M13 15.17l.03-.013-.134.057a2.474 2.474 0 01.618-.17l-.15.02c.082-.01.164-.017.247-.02a.592.592 0 00.395-.165.563.563 0 00-.395-.96 2.893 2.893 0 00-1.179.28.63.63 0 00-.259.336.564.564 0 00.395.691l.15.02a.553.553 0 00.284-.077z"
        className="cls-2"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.099"
        d="M26.727 14.055l.738.233a.434.434 0 00.224.01.42.42 0 00.21-.067.633.633 0 00.258-.336.568.568 0 00-.057-.434.602.602 0 00-.336-.258l-.738-.233a.637.637 0 00-.433.056.63.63 0 00-.26.337.569.569 0 00.058.433.611.611 0 00.336.259z"
        className="cls-2"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.099"
        d="M23.684 22.115l-7.617-1.003c.243 1.034.838 1.945 1.424 2.832.245.37.505.755.889.97.623.34 1.389.157 2.073-.036.708-.197 1.441-.41 1.996-.889.494-.428.8-1.034.995-1.658"
        className="cls-2"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.099"
        d="M23.684 21.552l-7.617-1.003c-.378-.05-.62.395-.542.713.256 1.028.803 1.934 1.382 2.811.58.877 1.228 1.63 2.377 1.592.51-.017 1.007-.152 1.495-.296a7.313 7.313 0 001.389-.518c.95-.493 1.503-1.382 1.827-2.378.225-.692-.862-.988-1.087-.297-.245.75-.642 1.369-1.361 1.731a6.082 6.082 0 01-1.07.387c-.39.11-.79.238-1.197.25-.826.027-1.178-.678-1.573-1.29-.463-.718-.889-1.452-1.097-2.286l-.543.712 7.617 1.002c.303.04.563-.286.563-.562 0-.338-.26-.523-.563-.563z"
        className="cls-2"
      />
    </svg>
  );
}

export default FilledHeart;

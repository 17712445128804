import React from "react";

function Icon() {
  return (
    <svg
      id="top-leaf"
      xmlns="http://www.w3.org/2000/svg"
      width="196"
      height="164"
      fill="none"
      viewBox="0 0 196 164"
    >
      <g fill="#C4FBEA" fillRule="evenodd" clipRule="evenodd">
        <path d="M110.723 80.772c-2.854-6.506-9.557-11.597-16.514-16.262-13.544-9.082-20.829-14.698-25.656-27.983-2.839-7.813-5.728-16.093-14.732-21.762-6.556-4.128-17.84-7.596-26.608-4.64-13.488 5.5-17.11 16.788-15.75 26.895 1.686 12.528 10.25 20.252 26.706 24.866 9.271 2.599 15.434 6.028 20.467 12.003 4.026 4.778 6.373 10.76 11.432 15.091 7.564 6.475 17.029 9.353 28.695 8.277-10.927-13.735-23.695-27.6-39.093-39.164-1.442-1.163-9.944-5.613-8.23-7.594 1.89-2.182 10.618 6.013 10.556 5.964 4.475 3.51 8.616 7.239 12.683 11.004-.01.004-.023.005-.034.01A630.737 630.737 0 0187.059 79.23c1.648 1.68 3.251 3.38 4.787 5.123 2.253 2.558 5.828 9.818 9.515 11.39a7.853 7.853 0 002.2.603c2.515 2.813 4.963 5.65 7.29 8.52 6.079 7.495 11.297 15.409 13.692 23.872.699 2.47.204 11.709 2.672 13.492 4.512 3.261 3.19-3.019 2.92-4.915-2.241-15.698-10.49-29.072-22.467-42.269 3.68-3.283 4.211-11.639 3.055-14.275z"></path>
        <path d="M22.416 119.017c4.756-3.829 16.19-4.479 23.184-4.222 8.654.317 17.542 1.756 25.775 3.496 13.562 2.866 26.74 8.097 38.684 13.16 1.439.61 19.173 7.896 17.603 9.175-1.555 1.266-18.963-7.388-22.843-8.88-8.28-3.184-15.872-6.949-24.998-9.052-9.45-2.177-19.443-3.039-29.38-2.98-1.275.008-5.435-.6-5.525.689-.087 1.261 6.796.603 8.01.611a133.27 133.27 0 0114.998.947c9.26 1.112 17.964 3.352 25.894 6.788-2.213 3.515-7.517 5.135-13.082 4.256-4.809-.761-9.497-2.636-14.206-3.178-3.243-.374-6.49-.152-9.636.473-8.295 1.647-13.08 5.021-22.201 2.674-7.15-1.84-19.109-8.451-12.277-13.957z"></path>
        <path d="M129.007 138.527h-2.655c.186-1.953.428-3.904.997-5.835 2.135-7.241 8.81-17.876 23.45-19.051.54-.044 2.527-.051 4.933.03a14.102 14.102 0 013.764-.637c12.465-.527 26.384 5.239 32.397 11.239 4.846 4.838 4.03 11.191 1.036 16.331-2.005 3.442-6.38 8.348-13.676 8.396-9.129.059-9.569-6.41-9.745-10.192-.235-5.029-1.343-9.409-8.253-12.836-1.61-.799-3.37-1.501-4.997-2.289-2.547-1.232-5.362-2.494-5.69-4.533-.453-2.813 2.316-3.319 6.544-2.796.721.089 7.98 1.957 6.905.48-.328-.451-1.526-.808-3.039-1.076-3.915-.244-8.583-.527-9.926-.358-14.485 1.822-20.124 13.327-21.622 20.637a41.744 41.744 0 00-.423 2.49z"></path>
      </g>
    </svg>
  );
}

export default Icon;

import React from "react";

function Cart() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      data-name="圖層 1"
      version="1.1"
      viewBox="0 0 25 31.036"
    >
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M0 11.06c.09 5.794.17 11.588.271 17.38.008.478.01.974.3 1.377.323.443.862.58 1.372.64 1.39.161 2.804.2 4.2.277q4.262.23 8.53.286 4.856.06 9.71-.11a.457.457 0 00.445-.444c.49-5.614-.146-11.23-.88-16.793-.132-.998-.027-2.581-1.112-3.075-.442-.2-.952-.203-1.428-.238q-1.123-.08-2.249-.142-4.255-.231-8.52-.18-4.796.058-9.58.471c-.564.05-.57.94 0 .888a129.275 129.275 0 0116.143-.383q2.007.081 4.011.22c.447.031 1.263-.049 1.519.415.135.242.137.602.173.867l.14 1.059c.348 2.63.677 5.264.876 7.91.225 2.988.277 5.99.017 8.978l.445-.445q-8.138.281-16.28-.08-2.058-.093-4.113-.227c-.633-.04-1.272-.065-1.903-.134-.225-.024-.535-.044-.713-.196-.263-.225-.212-.635-.217-.944-.047-2.7-.08-5.399-.126-8.098l-.144-9.28c-.009-.571-.898-.573-.887 0z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M10.97 7.33a43.558 43.558 0 00-6.775-6.242q-.337 1.25-.664 2.5c-.133.509-.264 1.05-.094 1.548.152.444.519.774.877 1.077a38.76 38.76 0 005.499 3.885c.187.109.508.161.547-.05"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M11.287 7.016A44.903 44.903 0 004.422.704C4.187.53 3.836.717 3.77.97q-.265.982-.524 1.965c-.15.574-.359 1.179-.322 1.78.064 1.214 1.205 1.936 2.07 2.621a39.672 39.672 0 003.05 2.192c.526.342 1.072.745 1.65 1.005.373.165.856.15 1.053-.26.247-.514-.52-.968-.768-.45.075-.155.03-.096-.096-.2a2.69 2.69 0 00-.313-.186q-.312-.188-.62-.382-.617-.389-1.22-.799-1.27-.866-2.466-1.833a11.81 11.81 0 01-1.04-.894c-.396-.408-.47-.843-.364-1.39.191-.987.507-1.963.767-2.933l-.653.266a43.837 43.837 0 016.685 6.173c.38.43 1.007-.2.63-.63z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M14.091 7.07a35.587 35.587 0 013.186-5.8c.242-.359.534-.741.96-.818.484-.087.928.252 1.296.575q1.4 1.23 2.692 2.582c.148.154.304.334.3.547-.005.214-.177.392-.338.54a32.79 32.79 0 01-7.37 5.074"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M14.475 7.296a36.128 36.128 0 012.596-4.903c.217-.341.43-.695.673-1.019.334-.447.666-.618 1.172-.275.756.514 1.427 1.254 2.077 1.892q.266.261.527.526c.127.129.415.33.479.497.075.198.028.21-.127.363-.155.154-.35.316-.525.469q-1.016.887-2.107 1.694a33.326 33.326 0 01-4.648 2.846c-.511.258-.061 1.026.449.768a33.3 33.3 0 004.363-2.639q1.048-.752 2.037-1.578c.488-.41 1.358-.935 1.501-1.6.155-.713-.617-1.271-1.055-1.71a39.93 39.93 0 00-1.97-1.85C19.383.31 18.733-.175 17.98.063c-.705.224-1.11 1.004-1.485 1.582a36.044 36.044 0 00-2.79 5.205c-.226.52.54.972.768.449z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M10.21 10.945c-1.72 2.68-4.63 4.642-7.466 6.534l3.467-.216a3.098 3.098 0 00-.535 2.111c1.175.081 2.123-.738 2.81-1.532 2-2.312 3.377-5.007 3.998-7.832l3.308 8.34.887-2.354a7.18 7.18 0 002.823 1.983c-.605-2.866-2.554-5.528-5.33-7.293"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M9.827 10.72c-1.79 2.743-4.631 4.59-7.307 6.373-.341.228-.242.858.224.83l3.467-.216-.384-.669a3.647 3.647 0 00-.596 2.34.457.457 0 00.445.445c2.134.076 3.598-2.085 4.646-3.66a18.078 18.078 0 002.584-6.033h-.854l3.307 8.34c.162.413.697.425.859 0l.887-2.355-.744.197c.824.946 1.863 1.625 3.02 2.098.337.137.61-.253.546-.547-.693-3.175-2.834-5.824-5.533-7.56-.484-.31-.93.459-.45.768 2.514 1.613 4.48 4.076 5.125 7.028l.548-.547a6.937 6.937 0 01-2.627-1.868c-.242-.275-.635-.091-.743.196l-.888 2.353h.859l-3.312-8.34c-.147-.372-.751-.47-.857 0a17.665 17.665 0 01-3.98 7.744c-.634.707-1.403 1.328-2.4 1.29l.445.445a2.858 2.858 0 01.474-1.888c.178-.273-.052-.69-.384-.668l-3.461.217.224.828c2.81-1.872 5.748-3.813 7.626-6.695.314-.484-.457-.927-.768-.449z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M8.717 22.329l-.182-.009a.444.444 0 00-.314.759.484.484 0 00.314.13l.182.01a.444.444 0 00.315-.76.484.484 0 00-.315-.13z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M17.092 23.139c.573 0 .573-.887 0-.887-.574 0-.574.887 0 .887z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M10.002 26.121a5.175 5.175 0 002.892.3c.423-.073.242-.716-.176-.646a4.554 4.554 0 01-2.539-.3c-.396-.16-.57.485-.177.646z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M13.84 7.584a10.091 10.091 0 00-2.603-.392c-.119 0-.251 0-.335.087a.458.458 0 00-.102.28q-.136 1.084-.275 2.168l2.404.156c.37.024.807.02 1.03-.275.41-.54-.34-1.326-.11-1.965"
        className="cls-1"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.081"
        d="M13.955 7.155a11.322 11.322 0 00-1.687-.334c-.441-.054-1.076-.19-1.496.013-.42.204-.415.69-.465 1.086l-.224 1.807c-.031.25.23.43.445.444l2.034.133c.494.032 1.033.097 1.475-.178.365-.226.52-.633.484-1.048-.037-.428-.356-.888-.242-1.316.15-.553-.708-.79-.857-.236a1.775 1.775 0 00.028.95c.074.276.371.791.007.94-.364.148-.979-.006-1.358-.03l-1.572-.103.446.446.137-1.085.068-.542.046-.361c-.02-.1.017-.134.11-.1.26-.102.932.066 1.195.107a10.828 10.828 0 011.193.266c.552.154.788-.704.236-.857z"
        className="cls-2"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.081"
        d="M12.288 23.33l2.041.766-.147-.558a1.87 1.87 0 01-1.65.417c-.418-.093-.597.55-.177.645a2.581 2.581 0 002.298-.588c.206-.179.07-.476-.147-.557l-2.04-.767c-.403-.15-.576.494-.178.645z"
        className="cls-1"
      />
    </svg>
  );
}

export default Cart;

import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="559"
      height="448"
      fill="none"
      viewBox="0 0 559 448"
      className="big-heart"
    >
      <g>
        <path
          fill="#E8B4B9"
          d="M286.23 86.45c-36.278-12.624-64.724-35.056-97.344-52.49-32.619-17.436-74.57-30.216-112.04-19.802-31.53 8.78-52.454 32.395-61.251 56.943-16.156 45.066 2.59 94.257 37.614 132.691 35.024 38.435 84.826 67.669 135.883 93.837 15.087 7.783 30.482 15.317 47.459 20.236 44.459 13.092 96.378 6.741 137.302-11.815 40.923-18.555 71.672-48.054 92.884-80.418 16.714-25.424 28.067-52.674 33.647-80.76 3.556-17.886 4.707-36.286-.391-53.939-10.667-36.815-50.337-67.917-98.66-77.226-17.923-3.456-37.799-3.923-54.016 2.786-33.647 13.901-36.01 49.41-56.421 73.973"
        ></path>
        <path
          fill="#E8B4B9"
          d="M288.329 78.168c-51.385-18.384-85.69-56.04-139.768-71.404-24.521-6.99-52.269-9.34-77.037-1.557C48.669 12.29 31.26 26.611 19.667 42.801c-25.754 35.943-23.945 79.624-7.42 117.591 17.841 40.972 54.16 75.296 96.44 103.519 21.17 13.896 43.414 26.824 66.616 38.714 23.144 12.08 46.987 24.58 74.673 29.717 53.893 9.994 110.437-3.425 152.656-29.577 41.56-25.778 70.562-62.454 88.691-100.359 19.423-40.598 32.886-90.287 11.017-131.865C482.668 33.009 432.455.49 377.205.49c-27.871 0-49.617 11.457-62.813 29.483-12.333 16.89-17.225 36.426-30.544 52.927-3.597 4.467-4.254 10.896 0 15.317 3.638 3.736 10.647 4.343 14.162 0 10.688-13.263 16.69-27.895 24.295-42.217 7.132-13.418 16.443-26.463 35.559-31.6 22.363-5.931 48.405 0 68.589 7.908 19.062 7.62 35.311 18.722 47.274 32.3 29.084 32.909 21.376 74.721 7.831 110.758-13.421 35.71-36.997 70.969-71.939 97.386-34.408 26.027-80.654 43.119-130.066 41.205-51.036-1.977-94.775-29.141-133.602-51.978C72.408 218.643-4.916 148.125 25.627 72.066c6.824-16.983 20.246-33.453 40.368-43.088 23.617-11.302 52.496-9.885 77.777-2.803 52.845 14.789 89.698 50.452 140.426 68.588 10.174 3.642 13.422-13.263 4.111-16.548l.02-.047z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M265.124 325.259l-19.567 106.009 6.844-5.682"
        ></path>
        <path
          fill="#E8B4B9"
          d="M257.213 322.892c-7.194 35.181-14.388 70.346-20.924 105.605-1.295 6.974 6.618 18.774 16.073 10.134 2.261-2.055 4.234-4.156 6.166-6.351 4.79-5.231.226-21.607-10.626-14.898-2.528 1.557-4.933 3.114-7.256 4.888l14.142 11.753c6.577-35.414 12.332-70.906 18.19-106.414.617-3.705-.164-9.433-5.735-10.897-5.57-1.463-9.372 2.662-10.092 6.227l.062-.047z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M346.99 323.375l1.378 85.415c0 3.907-.617 8.733-5.406 10.18"
        ></path>
        <path
          fill="#E8B4B9"
          d="M339.223 323.376c-.247 15.239-.863 30.479-.822 45.75 0 7.114 0 14.244.123 21.358 0 4.063.103 8.141.165 12.204 0 1.557 1.212 6.523-.391 7.674-6.413 4.577-5.775 21.794 7.112 18.229 9.558-2.615 12.106-10.897 12.538-17.793.575-9.246-.226-18.68-.473-27.942-.534-19.848-1.726-39.664-2.692-59.48-.514-10.352-15.395-10.492-15.56 0z"
        ></path>
        <path
          fill="#fff"
          d="M290.568 222.269c13.957.545 29.146.623 42.835-1.727 4.789-.825 4.111-9.434-1.151-9.216-13.977.545-27.625 1.993-41.684 1.993-5.549 0-5.57 8.733 0 8.95z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M457.324 214.221c28.019 13.965 51.747 32.328 69.576 53.846l-69.576-53.846z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M453.213 221.849c27.872 14.524 47.604 32.69 67.151 53.301 3.555 3.767 9.475 3.596 13.093 0 4.11-4.11 3.103-9.994 0-14.166-16.444-22.167-44.13-40.707-71.94-54.39-11.017-5.402-16.649 10.897-8.221 15.255h-.083z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M164.759 236.871l-74.2 77.165 74.2-77.165z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M158.899 230.52c-12.805 13.185-25.857 26.229-38.703 39.414-12.332 12.75-27.378 25.359-36.997 39.447-3.268 4.81 2.877 19.053 11.674 12.609 14.779-10.897 25.837-25.872 37.84-38.528 12.703-13.372 25.179-26.868 37.902-40.24 6.166-6.507-3.062-21.607-11.716-12.702z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M78.74 313.615c-3.762-1.774-6.557 4.577-2.384 5.682 4.172 1.105 8.324-2.693 7.79-6.024-.535-3.332-3.947-5.931-7.215-8.204-2.26-1.557-5.262-3.253-8.221-2.428-3.762 1.074-3.597 5.121-2.57 8.079 1.418 4.032 3.535 8.359 8.222 10.492 4.686 2.132 12.558.311 12.785-3.876-.06-1.535-.726-3.021-1.912-4.266-3.782-4.5-9.646-7.77-16.443-9.168a10.537 10.537 0 00-.278 5.414c.381 1.801 1.23 3.527 2.498 5.078 2.599.298 5.256-.04 7.584-.966"
        ></path>
        <path
          fill="#E8B4B9"
          d="M82.706 306.252c-16.094-7.394-23.72 17.342-8.633 22.027 9.414 2.942 17.163-4.763 18.499-10.896 1.768-7.675-2.59-14.213-9.558-19.505-5.96-4.499-14.758-7.535-21.479-2.055-5.775 4.67-6.166 12.173-4.11 18.073 2.055 5.899 5.24 12.453 12.332 16.049 2.94 1.55 6.463 2.34 10.043 2.253 3.581-.087 7.03-1.046 9.833-2.736 9.044-5.728 8.222-15.971 2.713-23.023-4.723-5.917-12.169-10.302-20.904-12.313-6.166-1.401-10.441 2.88-11.551 7.083-1.636 5.792-.793 11.827 2.405 17.232 1.829 2.958 4.213 5.713 8.858 6.056 3.461.17 6.918-.368 10.01-1.557 5.797-2.117 7.564-7.783 6.413-12.204-.966-3.736-5.323-8.796-11.284-6.943a11.384 11.384 0 01-5.139.436l6.599 2.957s-.412 1.806.226-.653l-11.531 7.098c2.535.489 4.945 1.294 7.132 2.382a38.276 38.276 0 015.22 4.172c-1.561-3.311-1.164-6.009 1.193-8.095.156-.192.385-.344.658-.436-.604.137-1.224.231-1.85.28a4.603 4.603 0 01-1.408-.596 3.423 3.423 0 01-.997-.96 21.034 21.034 0 01-1.542-3.254c-.698-1.556.124 0-.205.934-.576 1.557-4.111 3.191-3.392 4.483a25.44 25.44 0 004.44 3.394c-3.576-.592 0-5.044 1.46-5.822 3.267-1.043 5.159.981 5.631 6.071.26 3.352-1.795 5.184-6.166 5.495 10.133 2.242 13.422-12.126 6.043-15.567l.041.14z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M538.701 272.488c.802-3.316-8.016-2.974-7.852.373.165 3.347 6.331 4.903 10.277 3.3 3.947-1.603 5.879-4.934 7.482-8.063 1.11-2.164 2.055-4.888 0-6.616-2.693-2.273-7.605-.685-10.853 1.105-4.46 2.475-9.023 5.526-9.927 9.667-.904 4.141 4.111 8.982 9.414 7.612 1.866-.594 3.45-1.604 4.542-2.895 4.172-4.311 6.063-9.647 5.303-14.96-2.384.243-4.681.843-6.756 1.765-2.075.922-3.887 2.148-5.33 3.606.565 1.942 1.942 3.694 3.926 4.997"
        ></path>
        <path
          fill="#E8B4B9"
          d="M546.24 274.745c1.172-5.728-1.685-12.842-10.483-13.512-7.296-.545-12.085 4.141-13.278 9.153-1.623 6.881 3.433 14.789 13.114 15.879 7.399.825 13.504-2.974 17.08-7.581 4.646-5.978 8.078-13.606 4.769-20.44-3.186-6.538-11.366-9.449-19.814-6.351-15.991 5.9-26.268 22.167-13.628 33.749 7.379 6.771 17.944 5.043 24.378-1.557 6.066-6.4 8.949-14.219 8.139-22.073-.349-3.332-2.693-11.006-9.373-10.15-3.585.342-7.044 1.22-10.178 2.582-3.133 1.363-5.877 3.184-8.074 5.357-6.618 6.896-1.911 15.909 5.468 21.155 4.624 3.285 10.585 0 12.558-3.565 1.243-2.262 1.592-4.749 1.004-7.158-.587-2.41-2.086-4.639-4.313-6.416-.39-.638-.247-.342.452.872l-2.405 9.838c1.451-1.21 3.373-2.039 5.488-2.366l-9.372-10.149a8.426 8.426 0 01-.247 4.529 23.392 23.392 0 00-2.425 3.394c0 .514.719-.233 1.295.389.3.559.46 1.156.472 1.759 0 .187.74 3.69-.164 3.114-.308-.187 2.199-1.9 2.055-1.868a37.322 37.322 0 015.036-2.818c.183-.136.402-.24.644-.305.241-.064.499-.088.754-.069 3.35.343 1.85-1.868-4.461-6.631.823-.56-.76 1.23-.966 1.557-.358.879-1.078 1.648-2.055 2.195.164-.094.658-1.199-.966-.156h.185c1.424 1.531 2.322 3.308 2.61 5.168l-1.171 4.67c-.422.873-1.228 1.61-2.282 2.086-.575.249-.452.233.391 0-2.056.202-3.618-.763-4.563-2.786l-1.069-4.312v.576c.164 3.206 1.891 7.783 6.803 8.328 4.913.545 7.955-2.88 8.613-6.071l-.021-.016z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M251.968 422.426c-4.748-3.627-14.655.872-12.723 5.776 1.932 4.903 13.319 4.67 14.984-.265.986-5.059-7.461-9.34-13.566-7.161-6.105 2.18-7.626 9.123-3.309 13.045 4.316 3.923 12.805 4.359 18.313 1.401 2.056-1.052 3.636-2.556 4.529-4.31.893-1.753 1.056-3.671.466-5.497-1.48-3.565-7.625-5.806-11.818-3.814"
        ></path>
        <path
          fill="#E8B4B9"
          d="M257.457 416.496c-5.509-4.67-13.546-5.9-20.37-1.697-7.995 4.919-9.475 14.897-3.761 21.295 1.942 2.245 4.806 3.943 8.15 4.832 3.343.889 6.98.92 10.349.087 9.784-2.49 12.97-11.176 10.996-18.166-1.829-6.538-9.27-12.562-18.498-12.656-9.558-.093-16.053 6.476-18.027 12.952-2.158 7.052 0 15.566 7.462 20.408 3.04 1.944 6.739 3.205 10.676 3.639 3.938.435 7.958.026 11.604-1.18 11.346-3.969 16.444-14.399 13.689-23.35-2.281-7.363-12.99-15.13-23.431-10.787-5.735 2.397-7.708 7.643-6.495 12.36.925 3.611 5.467 9.09 11.428 7.02 1.603-.56 0-.358 1.767-.265-1.5 0 .884-.28.329.374l-.411-.296c-1.084-1.324-1.541-2.889-1.295-4.436-.164-.125.288-.779.185-.888.247.25-2.733 1.043-2.857 1.059-1.883.332-3.862.103-5.529-.638.72.28-.719-1.23-.185-.218.39.584.667 1.208.822 1.852a5.595 5.595 0 01-.431 2.257c-.699.763-.658.717.123-.155l-.349.389c-1.172-.187 1.418.078.37-.109.597.156 1.219.25 1.849.28l-.267-.202c.966.654 1.151.7.535.14.205-.732-.72-5.153-.946-2.491l1.254-5.028c-1.069 2.429 3.042-2.568.904-.918 1.008-.779-.596-.203.535-.187-.165 0-2.94.234-1.11.187a6.908 6.908 0 01-1.254-.312c-.637-.217-.534-.171.37.172l.699.591c.879 1.056 1.432 2.248 1.617 3.488a6.424 6.424 0 01-.548 3.673l-.391.685c-.658.56-.534.545.391-.078-.864-.187 2.055.14.575-.529 9.846 4.576 15.066-8.313 9.393-13.185l.083.031z"
        ></path>
        <path
          fill="#E8B4B9"
          d="M350.384 417.943c-4.11-.498-7.522 3.69-5.57 6.523.569.666 1.308 1.238 2.174 1.685a9.347 9.347 0 002.867.913c1.027.157 2.084.156 3.111-.001 1.026-.158 2-.469 2.865-.916 1.681-.978 2.959-2.298 3.687-3.806.728-1.507.875-3.141.424-4.709-1.356-5.386-9.229-9.433-16.176-7.955-6.947 1.479-10.791 8.609-6.7 13.123 4.09 4.514 14.901 3.985 17.738-1.043 2.836-5.028-5.077-11.41-11.716-9.262"
        ></path>
        <path
          fill="#E8B4B9"
          d="M350.383 409.6c-8.612-.623-13.73 6.6-14.203 12.173-.616 7.005 5.015 14.757 15.293 14.866 9.639.109 15.723-7.129 17.121-13.574.852-3.538.496-7.183-1.032-10.588-1.529-3.405-4.18-6.458-7.703-8.87-2.752-1.859-6.14-3.093-9.775-3.561-3.635-.468-7.371-.151-10.78.914-7.337 2.491-11.654 8.453-12.866 14.244-1.378 6.584 1.13 14.446 8.776 18.353 2.924 1.507 6.364 2.338 9.901 2.39 3.536.053 7.016-.675 10.016-2.094 17.636-8.391 7.934-36.924-14.614-30.994-11.079 2.911-7.893 22.479 4.913 19.288.164 0 .431 0 1.377.233-.658-.265-.494-.125.493.436a1.69 1.69 0 01-.513-.359 1.238 1.238 0 01-.289-.482c-.839-1.728-.802-3.63.103-5.339.203-.471.531-.906.966-1.277l-.308.109c-1.131.234-1.274.312-.452.203-.576.093-3.679-.171-2.549 0-.76-.14-1.172.093-1.254-.452.144.919 1.542 1.759 1.521 3.005a3.095 3.095 0 01-.452 1.837c.534-.934.555-1.012 0-.203.296-.392.754-.701 1.303-.878a3.551 3.551 0 011.739-.102c1.336.077 5.036 1.463 4.604 2.179.103-.171-.678-1.697-.575-2.164.024-.303.134-.599.322-.867.188-.269.45-.504.767-.69.432.218.123.358-.945.405-.638-.981-1.131.234-.884.094 3.206-1.868 2.425 8.452-.144 8.483 10.462-.14 10.277-15.94 0-16.687l.123-.031z"
        ></path>
        <path
          fill="#fff"
          d="M309.254 193.44c11.428 0 11.469-18.431 0-18.431s-11.469 18.431 0 18.431z"
        ></path>
        <path
          fill="#fff"
          d="M243.665 170.822c2.24 1.557 2.631 1.806 1.171.638a4.66 4.66 0 00-1.028-.825c-1.273-.837-2.879-1.33-4.563-1.401-2.273-.096-4.511.46-6.269 1.557-4.666 2.646-6.31 7.69-6.289 11.846 0 6.569 5.57 17.699 16.608 16.08 2.399-.46 4.607-1.367 6.424-2.64 1.816-1.273 3.183-2.869 3.976-4.645 2.223-4.648 2.135-9.75-.247-14.352-1.603-3.332-4.357-7.317-9.372-8.048-8.119-1.183-11.449 5.588-11.84 10.554-.267 3.316 2.056 8.717 6.927 9.854 4.872 1.136 9.065-1.355 10.668-4.67l.123-.249-5.673 4.67c-3.864.902-6.605-.213-8.222-3.347l.391.654c1.048 1.665-.185-1.246-.185-1.837 0-1.713.966-5.386 3.926-6.227a4.98 4.98 0 012.194-.117c.731.124 1.398.409 1.917.818 1.007.965 1.254 1.167.76.638-.493-.529-.308-.28.617.887a8.074 8.074 0 01-.432-.841c-.329-.684-.205-.435.329.748a3.882 3.882 0 01-.205 2.988c-.055.99-.452 1.951-1.151 2.787l.616-.607c.473-.436.843-.887 1.295-1.339l-3.453 2.179.534-.233h-4.008l.596.156c10.483 2.708 12.6-12.796 3.988-15.676h-.123z"
        ></path>
        <path
          fill="#fff"
          d="M373.873 155.457c-2.221.154-4.337.787-6.097 1.825-1.759 1.037-3.089 2.435-3.831 4.028-1.18 2.378-1.587 4.937-1.186 7.459.401 2.523 1.597 4.935 3.488 7.034 2.158 2.438 5.139 4.395 8.633 5.666 2.336.724 4.93.821 7.349.274 2.419-.546 4.519-1.703 5.95-3.278 5.775-5.947 4.11-14.337-.905-20.237-2.302-2.631-5.858-5.246-10.277-5.355-6.166-.14-10.277 4.452-11.202 8.639-.802 3.674 0 9.045 4.316 11.489.857.517 1.85.89 2.912 1.093 1.062.204 2.17.233 3.248.085a9.178 9.178 0 003.004-.939c.902-.472 1.66-1.084 2.223-1.796-1.603 1.136-1.952 1.463-1.007.981l-2.302 1.058.432-.14-2.549.374c.305-.047.62-.047.925 0l-2.549-.374c.368.048.719.154 1.028.311l-2.281-1.058c1.582.903-1.686-1.557.143.124 1.254 1.152-1.151-2.148-.39-.622.432.856.637 2.039-.267-.903.397 1.858.431.498.102-4.079.396-1.833 1.564-3.52 3.33-4.81l2.302-1.058a3.218 3.218 0 01-1.027.358l2.569-.374c-.414.063-.84.063-1.254 0l2.569.374a9.423 9.423 0 01-1.85-.53l2.303 1.059a25.953 25.953 0 01-3.042-1.681c-.576-.374-1.645-1.339.308.327l-.35-.405c3.371 4.058 2.515 8.209-2.569 12.453 4.83-1.354 6.166-6.226 5.838-9.682-.37-3.113-2.919-7.893-8.017-7.659l-.02-.031z"
        ></path>
        <path
          fill="#fff"
          d="M230.778 164.595c-.69 2.45-.283 5.004 1.157 7.259 1.441 2.256 3.832 4.083 6.797 5.194 2.241.841 2.96-2.008 2.775-3.02a20.677 20.677 0 010-7.114h-4.234c.288 1.715 1.028 3.369 2.175 4.864 1.147 1.495 2.679 2.8 4.505 3.838.207.123.447.211.702.258.256.048.522.053.781.016s.504-.115.72-.229c.216-.115.396-.263.531-.434 1.578-1.613 2.593-3.502 2.953-5.491.359-1.989.05-4.014-.898-5.889a1.39 1.39 0 00-.435-.625 2.048 2.048 0 00-.771-.398 2.51 2.51 0 00-.927-.078 2.33 2.33 0 00-.868.261c-.537.421-.897.953-1.036 1.529a2.32 2.32 0 00.255 1.709 4.68 4.68 0 01.149 2.91c-.261.965-.827 1.868-1.649 2.631l2.733-.389c-1.266-.66-2.339-1.511-3.153-2.499-.813-.989-1.349-2.093-1.574-3.245-.452-2.195-3.762-2.164-4.234 0a25.166 25.166 0 00-.185 9.683l2.939-2.958c-1.742-.626-3.18-1.652-4.109-2.932-.93-1.279-1.304-2.748-1.071-4.198.185-2.272-3.247-2.973-3.987-.591l-.041-.062z"
        ></path>
        <path
          fill="#fff"
          d="M364.852 152.795c.586 2.688 2.432 5.126 5.2 6.865.312.209.714.325 1.13.325a2.05 2.05 0 001.131-.325c1.864-1.414 3.016-3.278 3.256-5.268.24-1.99-.446-3.981-1.941-5.628-2.055-2.289-4.604 1.245-3.494 2.911 1.336 1.961 5.694 9.34 9.722 7.285 1.891-.934 2.323-3.238 2.549-4.81.299-2.166-.031-4.355-.966-6.414-1.192-2.708-4.769-.202-4.111 1.868.348.918.554 1.864.617 2.818 0 .482-.185.965-.165 1.432l-.185.685c.679-.265.761-.545.288-.825-.226-.685-1.603-1.65-2.055-2.273-.761-.872-1.48-1.774-2.056-2.677l-3.289 3.378c.496.491.726 1.107.646 1.722-.08.615-.465 1.184-1.077 1.593h2.261c-1.731-.946-3.036-2.272-3.741-3.798-1.007-2.631-4.317-1.183-3.72 1.105v.031z"
        ></path>
      </g>
    </svg>
  );
}

export default Icon;

import React from "react";

function Logo() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="264.474"
      height="60"
      data-name="圖層 2"
      version="1.1"
      viewBox="0 0 264.474 158.65"
    >
      <path
        fill="#d28e77"
        d="M11.5 155.1a8.29 8.29 0 01-6.22 3.45c-2.81 0-5.28-3.2-5.28-6.9a14.27 14.27 0 012.51-7.28c2.18-3.24 5.33-5.33 8-5.33a3.72 3.72 0 013.76 3.74c0 1.62-.94 2.77-2.26 2.77a1.71 1.71 0 01-1.79-1.77c0-.77.3-1.32 1.24-2.17.3-.3.42-.47.42-.73 0-.64-.59-1.06-1.61-1.06-1.75 0-3 1-4 3.06-1.62 3.41-3 8.82-3 11.55 0 2.3.77 3.36 2.43 3.36 2.55 0 5.49-2.43 7.15-6l.56.34c-.72 1.18-1.35 2.2-1.91 2.97z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M21.86 158.55c-3.15 0-5.83-3.2-5.83-6.94 0-4.9 4.93-12.53 10.52-12.53a4.12 4.12 0 014.08 3.92c0 3.37-3.79 5.5-10.52 6.31a17.09 17.09 0 00-.85 4.68c0 2.34 1 3.67 2.9 3.67 2.25 0 4.34-1.28 7.11-4.39l.47.42c-2.6 3.09-5.15 4.86-7.88 4.86zm3.24-11.46a6.66 6.66 0 002.72-4.85 2 2 0 00-2-2.26c-2.3 0-3.75 2.3-5.32 8.52 3.25-.72 3.75-.85 4.6-1.41z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M32.81 157.95l4.26-17.63h-2.85l.13-.68h6l-.85 3.53c2.17-2.93 3.53-4 5.15-4 1.45 0 2.56 1.57 2.56 3.49 0 1.92-.85 3-2.13 3a1.81 1.81 0 01-1.83-1.88 3.08 3.08 0 01.94-1.91c.55-.6.76-.9.76-1.2a.68.68 0 00-.68-.72c-1.66 0-3 1.87-4 3.36a11.06 11.06 0 00-1.32 2.94l-2.9 11.7z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M57.18 152.93c-1.66 4.21-3 5.66-5.41 5.66-2.08 0-3.45-1.62-3.45-4.21a6.41 6.41 0 01.22-1.79l3-12.44H48.6l.13-.68h3l2.39-9.58h3.19l-2.21 9.58h2.17l-.17.68h-1.58c-.51 0-.89.43-1.06 1.19l-3.24 13.85a4.39 4.39 0 00-.13 1c0 1 .51 1.62 1.28 1.62 1.36 0 2.51-1.37 4.17-5z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M73.33 155.19a2.55 2.55 0 00-.08.59c0 1 .47 1.66 1.06 1.66 1.83 0 3.11-3.15 3.79-4.81l.64.17c-1 2.43-2.47 5.66-5.32 5.66-1.92 0-2.73-2.3-3.11-4.21-1.53 3.06-3.37 4.3-5.32 4.3-3 0-5.59-3.53-5.59-7.79a12 12 0 011.28-5.16c1.84-3.87 5.33-6.69 8.35-6.69 1.88 0 2.86 1.07 4.05 4.31l.81-3.67h3.15zm-3.36-2.09c1.4-4.26 2.13-7.45 2.13-9.46 0-2.21-1.41-4-3.24-4-1.83 0-3.15 1.79-4.13 5.28-1.4 4.94-2 7.46-2 9.12 0 2.25.89 3.58 2.43 3.58 2.04.04 3.96-1.92 4.81-4.52z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M88.85 139.64l-3.5 14.95a7 7 0 00-.21 1.49c0 1.06.43 1.7 1.15 1.7 1.92 0 3.45-3.15 4.22-5.24l.51.17c-1.36 4.05-3 5.88-5.28 5.88a3.8 3.8 0 01-3.49-4 9.94 9.94 0 01.34-2.6l2.72-11.67h-3.19v-.68zm2.77-9.84a2.34 2.34 0 01-2.3 2.38 2.23 2.23 0 01-2.13-2.29 2.33 2.33 0 012.3-2.35 2.23 2.23 0 012.13 2.24z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M97.84 140.36h-3.37l.22-.76 6.68-.13-1.1 4.81c3.28-4.17 5.06-5.28 7.15-5.28 2.56 0 4.43 2.13 4.43 5.11a3 3 0 01-.13.94l-2.89 9.73a4.34 4.34 0 00-.17 1.28c0 1 .42 1.66 1.1 1.66a2.41 2.41 0 001.5-.6 7.18 7.18 0 001.7-2.47c.25-.59.55-1.28.89-2h.73c-1.62 4.34-3.16 6-5.5 6-1.79 0-3-1.53-3-3.88a6.75 6.75 0 01.21-1.57l2.77-10a5.41 5.41 0 00.09-.85c0-1.53-.77-2.43-2.17-2.43-1.67 0-2.94.85-4.65 3a15.37 15.37 0 00-2.94 5l-2.55 10.05h-3.09z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M119.62 156.29c0 .9 1.32 1.62 3 1.62 2.47 0 4.17-1.32 4.17-3.19 0-1.58-.21-1.84-4-5.2-1.83-1.66-2.85-3.45-2.85-5a5.49 5.49 0 015.81-5.44c3 0 5.32 1.75 5.32 3.92a2.18 2.18 0 01-2 2.35 1.69 1.69 0 01-1.83-1.71 3.15 3.15 0 01.64-1.7 2.76 2.76 0 00.6-1c0-.26-.43-.6-1-.81a4.14 4.14 0 00-1.66-.34c-2.17 0-3.74 1.32-3.74 3.06 0 1.2.89 2.39 3.23 4.22 2.6 2 3.71 3.79 3.71 5.79 0 3.15-2.86 5.75-6.31 5.75-3 0-5.92-2.26-5.92-4.6a2.27 2.27 0 012.18-2.34 1.87 1.87 0 011.87 2 2 2 0 01-.26 1.15l-.8.94a.72.72 0 00-.16.53z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M158.26 158.55c-1.7 0-2.68-1.36-3.19-4.51-1.58 3.06-3.37 4.51-5.5 4.51-2.94 0-5.53-3.45-5.53-7.41a12.54 12.54 0 011.1-5c1.75-4 5.37-7 8.56-7 1.88 0 2.69.72 4 3.62l3.32-14.27h-3.27v-.68h6.73l-6.43 27.09a5.19 5.19 0 00-.17 1.32c0 .85.29 1.36.85 1.36 1.23 0 2.56-1.7 3.87-5l.56.22c-1.36 3.79-3.03 5.75-4.9 5.75zm-3.15-6.65a30.49 30.49 0 001.62-7.75c0-2.76-1.15-4.38-3.15-4.38-1.79 0-2.94 1.4-4.14 5a36.67 36.67 0 00-2 9.79c0 2 .9 3.12 2.3 3.12 2.17-.02 4.09-2.07 5.37-5.78z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M171.3 158.55c-3.15 0-5.83-3.2-5.83-6.94 0-4.9 4.94-12.53 10.52-12.53a4.12 4.12 0 014.09 3.92c0 3.37-3.79 5.5-10.52 6.31a17.09 17.09 0 00-.85 4.68c0 2.34 1 3.67 2.89 3.67 2.26 0 4.35-1.28 7.12-4.39l.46.42c-2.59 3.09-5.15 4.86-7.88 4.86zm3.24-11.46a6.67 6.67 0 002.73-4.85 2 2 0 00-2-2.26c-2.3 0-3.75 2.3-5.33 8.52 3.28-.72 3.75-.85 4.6-1.41zm3.2-14.1a19.34 19.34 0 00-3.92 1.88l-.43-.51a33.65 33.65 0 003.41-3.45c1-1.19 2.38-3.45 4.17-3.45a1.81 1.81 0 012 1.7c.01 2.13-3.44 3.24-5.22 3.83z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M191.29 152.93c-1.66 4.21-3 5.66-5.41 5.66-2.09 0-3.45-1.62-3.45-4.21a6.4 6.4 0 01.21-1.79l3-12.44h-2.89l.13-.68h3l2.38-9.58h3.2l-2.22 9.58h2.17l-.17.68h-1.57c-.51 0-.9.43-1.07 1.19l-3.23 13.85a3.91 3.91 0 00-.13 1c0 1 .51 1.62 1.28 1.62 1.36 0 2.51-1.37 4.17-5z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M207.44 155.19a2.09 2.09 0 00-.09.59c0 1 .47 1.66 1.07 1.66 1.83 0 3.11-3.15 3.79-4.81l.63.17c-1 2.43-2.47 5.66-5.32 5.66-1.91 0-2.72-2.3-3.11-4.21-1.53 3.06-3.36 4.3-5.32 4.3-3 0-5.58-3.53-5.58-7.79a11.85 11.85 0 011.28-5.16c1.83-3.87 5.32-6.69 8.34-6.69 1.88 0 2.86 1.07 4.05 4.31l.81-3.67h3.15zm-3.37-2.09c1.41-4.26 2.13-7.45 2.13-9.46 0-2.21-1.4-4-3.23-4s-3.16 1.79-4.14 5.28c-1.4 4.94-2 7.46-2 9.12 0 2.25.9 3.58 2.43 3.58 2.04.04 3.96-1.92 4.81-4.52z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M222.95 139.64l-3.49 14.95a6.47 6.47 0 00-.22 1.49c0 1.06.43 1.7 1.16 1.7 1.91 0 3.44-3.15 4.21-5.24l.51.17c-1.36 4.05-3 5.88-5.28 5.88a3.8 3.8 0 01-3.49-4 9.94 9.94 0 01.34-2.6l2.73-11.67h-3.2v-.68zm2.8-9.86a2.33 2.33 0 01-2.3 2.38 2.23 2.23 0 01-2.13-2.29 2.33 2.33 0 012.3-2.35 2.23 2.23 0 012.13 2.26z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M232.16 158.59c-2.05 0-3.32-1.66-3.32-4.3a11.79 11.79 0 01.33-2.94l5.41-22.87h-3.49l.17-.68h6.82l-6.52 26.78a5.73 5.73 0 00-.13 1.16c0 1.06.56 1.78 1.41 1.78a1.69 1.69 0 001.15-.46 7.14 7.14 0 001.62-2.69c.21-.55.42-1.19.68-1.91l.64.12c-1.54 4.61-2.64 6.01-4.77 6.01z"
        className="cls-1"
      ></path>
      <path
        fill="#d28e77"
        d="M242.13 156.29c0 .9 1.32 1.62 3 1.62 2.47 0 4.17-1.32 4.17-3.19 0-1.58-.21-1.84-4-5.2-1.83-1.66-2.85-3.45-2.85-5 0-3.15 2.47-5.46 5.84-5.46 3 0 5.32 1.75 5.32 3.92a2.19 2.19 0 01-2 2.35 1.69 1.69 0 01-1.83-1.71 3.13 3.13 0 01.63-1.7 2.61 2.61 0 00.6-1c0-.26-.43-.6-1-.81a4.18 4.18 0 00-1.66-.34c-2.17 0-3.75 1.32-3.75 3.06 0 1.2.89 2.39 3.24 4.22 2.59 2 3.7 3.79 3.7 5.79 0 3.15-2.85 5.75-6.3 5.75-3 0-5.92-2.26-5.92-4.6a2.27 2.27 0 012.17-2.34 1.86 1.86 0 011.87 2 2 2 0 01-.25 1.15l-.81.94a.72.72 0 00-.17.55z"
        className="cls-1"
      ></path>
      <path
        id="u"
        fill="#d28e77"
        d="M35.55 2.35L14.37 63.13a56.61 56.61 0 00-2.21 6.91 20.78 20.78 0 00-.74 4.51 8 8 0 001.38 4.61q1.38 2 4.7 2c3.31 0 6.91-1.26 10.77-3.77a62 62 0 0011.33-9.61 87.62 87.62 0 0010.15-13.21 73.63 73.63 0 007.18-14.46L70.01 2.35H82.9L61.15 64.78c-.61 1.84-1.23 3.72-1.84 5.62a17.48 17.48 0 00-.92 5.43 5.78 5.78 0 001.29 3.78 4.72 4.72 0 003.87 1.56 10.18 10.18 0 006-2 29.47 29.47 0 005.16-4.7 40.23 40.23 0 004-5.43 45.89 45.89 0 002.39-4.42l2.21.92q-.74 1.47-2.3 4.51a28.81 28.81 0 01-4.33 6.08 26 26 0 01-6.81 5.25 19.79 19.79 0 01-9.58 2.21q-6.08 0-9.67-2.49t-3.59-8.38a24 24 0 01.73-6 55.73 55.73 0 012.22-6.54l-.37-.36q-2.58 3.32-6.17 7.46a76.33 76.33 0 01-7.74 7.73 47 47 0 01-8.84 6.08 20.39 20.39 0 01-9.67 2.49q-7.92 0-12.52-3.69T.06 69.21a20.75 20.75 0 011.29-6.54c.86-2.51 1.6-4.76 2.21-6.72l18-51H5.16l.74-2.58z"
        className="cls-1"
      ></path>
      <path
        id="n"
        fill="#d28e77"
        d="M102.22 4.93l.74-2.58h29.1L124.51 25h.37q17.87-23.75 33.71-23.75 7.72 0 12.24 4.14a13.32 13.32 0 014.51 10.22 19.22 19.22 0 01-1 6.17c-.67 2-1.5 4.45-2.48 7.28l-12 34.07c-.73 2.09-1.41 4.15-2 6.17a20.43 20.43 0 00-.92 6 8.86 8.86 0 00.92 4.05c.61 1.23 1.9 1.84 3.87 1.84a10.68 10.68 0 006.07-1.84 26.42 26.42 0 005.07-4.42 33.88 33.88 0 004-5.43c1.1-1.91 2-3.53 2.58-4.88l2 .92c-.61 1.23-1.47 2.85-2.58 4.88a31 31 0 01-4.32 6 24.79 24.79 0 01-6.54 5.07 18.93 18.93 0 01-9.26 2.09 18.25 18.25 0 01-10-2.58q-4-2.58-4-8.48a20 20 0 01.65-5q.63-2.58 1.56-5.52l15.47-44a24.3 24.3 0 001.48-7.36 7.91 7.91 0 00-1.57-4.61c-1-1.47-2.92-2.21-5.62-2.21s-5.83 1-9.39 3a52.56 52.56 0 00-10.68 8.29 76 76 0 00-10 12.34A71 71 0 00119 42.83l-13.81 38.5H92.46l26.52-76.44z"
        className="cls-1"
      ></path>
      <path
        id="e"
        fill="#d28e77"
        d="M250.12 68.01a33.58 33.58 0 01-6.07 6.82 40.88 40.88 0 01-9.49 6.08 31.5 31.5 0 01-13.54 2.67A31.12 31.12 0 01207.67 81a25.71 25.71 0 01-8.84-6.63 26.17 26.17 0 01-5-8.94 31 31 0 01-1.56-9.48 45.68 45.68 0 014.51-19.07 72.68 72.68 0 0111.79-18 66.51 66.51 0 0116.48-13.54A37 37 0 01243.84 0a33.63 33.63 0 017.37.82 24.9 24.9 0 016.63 2.49 14.67 14.67 0 014.79 4.24 10.55 10.55 0 011.84 6.26 14.79 14.79 0 01-2.67 8.75 23.87 23.87 0 01-6.91 6.44 48.31 48.31 0 01-9.3 4.52 72.29 72.29 0 01-9.85 2.85c-3.2.68-6.11 1.17-8.75 1.48-2.64.31-4.58.46-5.8.46q-3.13 0-6.08-.19a49 49 0 01-5.16-.55q-2 5.9-3.41 11.69a52.86 52.86 0 00-1.38 12.25q0 9.94 5.16 14.83a18.21 18.21 0 0013.08 4.88 22.65 22.65 0 0010.4-2.3 35.72 35.72 0 007.92-5.43 41.8 41.8 0 005.71-6.36q2.3-3.23 3.78-5.06l2.21 1.28a52.05 52.05 0 00-3.3 4.66zm.74-62.9q-2.21-2.58-7.37-2.58a20.23 20.23 0 00-11 3.32 42.5 42.5 0 00-9.48 8.2 61.92 61.92 0 00-7.37 10.49 66.59 66.59 0 00-4.79 10.41 55.12 55.12 0 009.39.74 38 38 0 0012.53-2 38.69 38.69 0 0010.41-5.34 26.93 26.93 0 007.18-7.73 17.58 17.58 0 002.67-9.21 9.33 9.33 0 00-2.17-6.3z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Logo;

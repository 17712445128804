import React from "react";

function Instagram() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="54"
      height="54"
      data-name="圖層 1"
      viewBox="0 0 512 512"
    >
      <path
        fill="#d28e77"
        d="M405.18 183.84c-2.94-14.53-16.43-39.29-25.27-50.38-19.74-14.15-32-20.31-59.82-28.2-32.79 1.62-108.7.67-145.2 11.52-26.87 5.27-47 21.57-59.29 49.4-6.63 23.29-11.11 144.05-7.31 172.31 9.91 26.65 26.92 47.06 67 59.12 24.11 2.51 134 6.57 164.42-.52 22.42-8.75 44.35-18.06 54.15-60.38 5.29-18.53 9.59-131.95 11.32-152.87zm-28.95 141.25c-11.57 29.57-21.31 41.75-45.46 49.48-32 .88-138 7.68-158.4-5.71-18.14-2.23-27-19.85-30-36.34-11.08-18.8-5.69-123.48-7.59-146 8.2-31.79 27.81-43.54 49.33-45.56 30.57-4.58 120.42-6.41 142.48-1.43 30.33 1.62 48 16.2 45.42 47.31 7.89 27.5 8.24 119.23 4.22 138.25zM253.49 183.47c-38.82-4-70.88 32.2-73.47 74.27 2.85 40.49 34.22 73.23 74 74 44.52-1.43 79.35-36.62 76-74.83 3.32-39.39-31.23-76.21-76.53-73.44zm3.21 119.34c-30 1.36-48.59-22.1-47.7-47-3.15-27.56 18.68-47 46.18-49.66 28 1.72 50.89 22.4 50.82 51.14-3.39 27.45-22.41 48.54-49.3 45.52zm95.51-125.49c-1.15 10.3-8 17.63-17.47 18.32-11-.27-18.87-7.39-18.61-18.18.44-9.19 8.81-17.52 18.6-16.81 8.58.22 17.51 7.95 17.48 16.67z"
        className="cls-1"
      ></path>
    </svg>
  );
}

export default Instagram;

import React from "react";

function FilledGift() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="49.657"
      data-name="圖層 1"
      version="1.1"
      viewBox="0 0 40 49.657"
      className="filledGift "
    >
      <path
        fill="#d28e77"
        strokeWidth="0.129"
        d="M0 17.695c.145 9.27.273 18.541.434 27.81.013.763.014 1.557.48 2.202.516.709 1.378.93 2.194 1.024 2.224.258 4.487.32 6.722.444q6.818.369 13.648.456 7.768.097 15.535-.175a.732.732 0 00.711-.711c.783-8.982-.232-17.968-1.407-26.868-.211-1.597-.044-4.13-1.78-4.92-.707-.321-1.522-.325-2.284-.38q-1.798-.13-3.598-.228-6.808-.37-13.632-.29-7.675.094-15.328.756c-.904.079-.914 1.502 0 1.42a206.839 206.839 0 0125.828-.612q3.21.129 6.418.35c.715.051 2.02-.077 2.43.666.217.387.22.962.276 1.387q.115.847.226 1.693c.556 4.208 1.082 8.423 1.4 12.656.36 4.782.444 9.584.027 14.366l.713-.713q-13.021.45-26.047-.129-3.294-.147-6.582-.361c-1.014-.066-2.036-.105-3.045-.214-.36-.039-.856-.071-1.14-.315-.422-.359-.34-1.016-.348-1.51-.075-4.319-.13-8.638-.201-12.957l-.23-14.847c-.015-.915-1.438-.918-1.42 0z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.129"
        d="M17.553 11.729A69.693 69.693 0 006.71 1.74q-.538 2-1.062 4c-.211.814-.422 1.678-.15 2.477.243.71.83 1.238 1.403 1.723a62.017 62.017 0 008.799 6.216c.299.174.813.258.875-.082"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.129"
        d="M18.06 11.225A71.845 71.845 0 007.075 1.126c-.375-.279-.937.02-1.045.426q-.423 1.57-.838 3.144c-.241.919-.574 1.886-.516 2.848.103 1.943 1.928 3.098 3.313 4.194a63.475 63.475 0 004.88 3.508c.841.545 1.715 1.19 2.64 1.606.597.265 1.37.24 1.685-.415.395-.822-.833-1.549-1.229-.72.12-.248.047-.154-.153-.32a4.305 4.305 0 00-.501-.299q-.5-.299-.993-.61-.987-.622-1.951-1.278-2.033-1.386-3.947-2.933a18.895 18.895 0 01-1.663-1.43c-.634-.653-.752-1.349-.582-2.225.305-1.579.81-3.14 1.227-4.692l-1.046.425a70.138 70.138 0 0110.697 9.877c.608.69 1.61-.321 1.007-1.007z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.129"
        d="M22.546 11.313a56.94 56.94 0 015.097-9.282c.387-.573.855-1.185 1.537-1.307.774-.14 1.484.402 2.072.92q2.241 1.968 4.307 4.13c.238.246.488.534.48.876-.008.342-.282.627-.54.863a52.465 52.465 0 01-11.793 8.12"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.129"
        d="M23.16 11.673a57.804 57.804 0 014.154-7.844c.347-.546.687-1.113 1.076-1.63.535-.717 1.066-.99 1.875-.44 1.21.822 2.285 2.005 3.323 3.026q.426.418.845.843c.202.205.663.526.765.795.121.316.045.334-.203.58-.248.247-.559.505-.84.75q-1.626 1.42-3.371 2.71a53.322 53.322 0 01-7.437 4.554c-.818.413-.098 1.642.718 1.229a53.28 53.28 0 006.981-4.222q1.678-1.203 3.26-2.526c.78-.654 2.172-1.494 2.402-2.56.248-1.14-.987-2.033-1.688-2.734a63.888 63.888 0 00-3.153-2.96C31.012.494 29.973-.281 28.769.1c-1.128.359-1.776 1.607-2.376 2.532a57.67 57.67 0 00-4.463 8.327c-.362.832.862 1.557 1.229.72z"
        className="cls-1"
      />
      <path
        fill="#d28e77"
        strokeWidth="0.129"
        d="M22.145 12.134a16.146 16.146 0 00-4.165-.626c-.191 0-.403 0-.537.138a.733.733 0 00-.163.448q-.218 1.734-.44 3.469l3.846.25c.593.038 1.29.033 1.648-.441.656-.865-.544-2.12-.177-3.143"
        className="cls-1"
      />
      <path
        fill="#fffaf0"
        strokeWidth="0.129"
        d="M22.328 11.447a18.115 18.115 0 00-2.699-.534c-.706-.085-1.721-.305-2.394.022-.672.326-.665 1.102-.745 1.737l-.357 2.891c-.05.399.368.69.711.711l3.255.212c.791.052 1.653.156 2.36-.284.584-.363.833-1.013.775-1.678-.06-.684-.57-1.42-.387-2.105.238-.885-1.133-1.263-1.372-.378a2.84 2.84 0 00.045 1.52c.119.442.594 1.266.011 1.504-.582.237-1.565-.01-2.172-.048l-2.515-.164.712.713.22-1.735.11-.867.073-.578c-.033-.16.027-.215.175-.16.416-.163 1.492.105 1.912.171a17.325 17.325 0 011.909.426c.884.245 1.26-1.127.378-1.372z"
        className="cls-2"
      />
      <path
        fill="#d28e77"
        fillOpacity="1"
        strokeWidth="0.213"
        d="M16.284 48.453c-6.799-.25-11.838.233-13.457-.628l-1.123-.598-.128-1.972c-.07-1.084-.19-7.598-.267-14.475l-.138-12.504 1.365-.413c4.297-.407 9.753-.629 16.226-.565 7.2-.112 16.561.308 17.23.658.442.232.745.68 1.385 5.781 1.129 8.998 1.396 10.936 1.406 17.718.006 3.598-.104 6.832-.483 7.286-.22.221-16.27.03-21.541-.163z"
      />
      <g transform="translate(0 18.621)">
        <path
          fill="#fffaf0"
          fillOpacity="1"
          strokeWidth="0.129"
          d="M15.396-1.634c-2.864 4.388-7.41 7.342-11.69 10.196-.546.364-.388 1.373.358 1.326l5.547-.344-.614-1.07a5.835 5.835 0 00-.954 3.743.732.732 0 00.713.712c3.413.121 5.756-3.336 7.432-5.855a28.925 28.925 0 004.136-9.653h-1.367l5.291 13.342c.258.663 1.115.682 1.374 0l1.42-3.766-1.19.315c1.317 1.514 2.98 2.6 4.83 3.356.54.22.978-.404.875-.875-1.11-5.08-4.535-9.318-8.854-12.096-.774-.497-1.486.735-.718 1.229 4.021 2.581 7.168 6.521 8.199 11.245l.876-.875a11.1 11.1 0 01-4.202-2.989c-.387-.44-1.016-.146-1.19.314l-1.42 3.764h1.374L20.324-2.958c-.237-.595-1.203-.753-1.372 0a28.264 28.264 0 01-6.368 12.39c-1.015 1.131-2.245 2.125-3.84 2.065l.711.71a4.573 4.573 0 01.76-3.02c.284-.437-.085-1.103-.615-1.07l-5.538.348.359 1.325c4.495-2.995 9.197-6.1 12.201-10.712.502-.774-.73-1.483-1.229-.717z"
          className="cls-1"
        />
        <g
          fill="#fffaf0"
          fillOpacity="1"
          transform="matrix(1.6 0 0 1.6 -62.9 -18.421)"
        >
          <path
            fill="#fffaf0"
            fillOpacity="1"
            strokeWidth="0.081"
            d="M48.827 21.145l-.182-.009a.444.444 0 00-.315.76.484.484 0 00.315.13l.182.01a.444.444 0 00.315-.76.484.484 0 00-.315-.13z"
            className="cls-1"
          />
          <path
            fill="#fffaf0"
            fillOpacity="1"
            strokeWidth="0.081"
            d="M57.201 21.955c.573 0 .574-.887 0-.887-.573 0-.573.887 0 .887z"
            className="cls-1"
          />
          <path
            fill="#fffaf0"
            fillOpacity="1"
            strokeWidth="0.081"
            d="M50.112 24.937a5.175 5.175 0 002.892.3c.422-.073.242-.716-.177-.646a4.554 4.554 0 01-2.538-.299c-.396-.161-.57.484-.177.645z"
            className="cls-1"
          />
          <path
            fill="#fffaf0"
            fillOpacity="1"
            strokeWidth="0.081"
            d="M52.398 22.146l2.04.766-.147-.557a1.87 1.87 0 01-1.65.417c-.417-.093-.596.55-.177.645a2.581 2.581 0 002.299-.588c.206-.18.069-.477-.148-.558l-2.04-.767c-.403-.15-.576.494-.177.646z"
            className="cls-1"
          />
        </g>
      </g>
    </svg>
  );
}

export default FilledGift;
